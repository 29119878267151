<div class="modal">
  <div class="modal-close">
    <i class="fal fa-times" mat-dialog-close></i>
  </div>
  <div class="modal-inner">
    <div class="row center">
      <div class="col align-center">
        <h2>Change Password</h2>
        <div class="form less-space-top">
          <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!formProcessed" novalidate>
            <div class="form-control" *ngIf="form.controls.password">
              <div class="has-password-toggle relative">
                <input type="password"
                       togglePassword
                       [formControl]="form.controls.password"
                       minlength="8"
                       placeholder="Password"
                       [ngClass]="{ 'is-invalid': form.controls.password.errors && formSubmitted}" />
                <div [@inOutAnimation] *ngIf="form.controls.password.errors && formSubmitted" class="invalid-feedback">
                  <p class="form-error" *ngIf="form.controls.password.errors.required">Password is required</p>
                  <p class="form-error" *ngIf="form.controls.password.errors.minlength">Password must be at least 8 characters</p>
                  <click-api-error [apiErrors]="apiErrors" key="password"></click-api-error>
                </div>
              </div>
            </div>
            <div class="form-control" *ngIf="form.controls.password">
              <div class="has-password-toggle relative">
                <input type="password"
                       togglePassword
                       placeholder="Confirm Password"
                       minlength="8"
                       [formControl]="form.controls.confirmPassword"
                       [ngClass]="{ 'is-invalid': form.controls.confirmPassword.errors && form.controls.confirmPassword.touched}" />
              </div>
              <div *ngIf="form.controls.confirmPassword.errors && form.controls.confirmPassword.touched">
                <p class="form-error" *ngIf="form.controls.confirmPassword.errors.mustMatch">Passwords must match</p>
              </div>
              <div [@inOutAnimation] *ngIf="form.controls.confirmPassword.errors && formSubmitted" class="invalid-feedback">
                <p class="form-error" *ngIf="form.controls.confirmPassword.errors.required">Confirm Password is required</p>
              </div>
            </div>
            <div class="button-col align-center">
              <button class="btn btn-md btn-purple" type="submit">
                <span *ngIf="!formProcessing">Save<i class="fa fa-chevron-circle-right margin-left"></i></span>
                <span *ngIf="formProcessing"><i class="fa fa-spin fa-cog"></i></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
