import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '@click/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SubaccountModel} from '../../models/subaccount.model';
import {Subscription} from "rxjs";
import {inOut} from '../../app.animations';

@Component({
  selector: 'app-edit-subaccount',
  templateUrl: './edit-subaccount.component.html',
  styleUrls: ['./edit-subaccount.component.scss'],
  animations: [inOut]
})
export class EditSubaccountComponent implements OnInit {

  public form: FormGroup;
  public formSubmitted = false;
  public formProcessing = false;
  public formProcessed = false;
  public apiErrors: any;
  private formChangeSubscription: Subscription;

  public subaccount: SubaccountModel;
  public editableModel: SubaccountModel;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditSubaccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.subaccount = data.subaccount;
  }

  ngOnInit(): void {

    this.editableModel = new SubaccountModel();
    this.editableModel.setData(this.data.subaccount);

    this.form = this.formBuilder.group({
      first_name: [this.editableModel.first_name, [Validators.required]],
      last_name: [this.editableModel.last_name, [Validators.required]],
      email: [this.editableModel.email, [Validators.email, Validators.required]],
      is_adult: [this.editableModel.is_adult, []],
      charge_account_permitted: [this.editableModel.charge_account_permitted, []],
      dob: [this.editableModel.dob, []]
    });

    this.formChangeSubscription = this.form.valueChanges.subscribe((values) => {
      this.onFormChange(values);
    });
  }

  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
  }

  resetDOB() {
    this.form.controls.dob.setValue(new Date());
  }

  onFormChange(values) {
    this.editableModel.setData(values);
    // setData isn't updating checkbox values here
    this.editableModel.is_adult = values.is_adult;
  }

  submit() {
    this.formSubmitted = true;
    if (!this.form.valid || this.formProcessing) {
      return;
    }
    this.dialogRef.close({event: 'UPDATE', data: this.editableModel});
  }

}
