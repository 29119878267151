import {animate, query, style, transition, trigger, stagger, group, animateChild, state} from "@angular/animations";

export const inOut =
  trigger(
    'inOutAnimation',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0 }),
          animate('{{ speed }} {{ delay }} ease-out',
            style({ opacity: 1 }))
        ],
        { params: { speed: '.3s', delay: '.1s' }}
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1, position:  '{{positionOnLeave}}' }),
          animate('{{ speed }} ease-in',
            style({  opacity: 0, position:  '{{positionOnLeave}}' }))
        ],
        { params: { speed: '.3s', positionOnLeave: 'relative' }}
      )
    ]
  );

export const inOutUp =
  trigger(
    'inOutUpAnimation',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY({{ distance }})' }),
          animate('{{ speed }} {{ delay }} ease-out',
            style({ opacity: 1, transform: 'translateY(0px)' }))
        ],
        { params: { speed: '.3s', delay: '.3s', distance: '20px' }}
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1, transform: 'translateY(0px)' }),
          animate('{{ speed }} ease-in',
            style({  opacity: 0, transform:'translateY(-{{ distance }})' }))
        ],
        { params: { speed: '.3s', distance: '20px' }}
      )
    ]
  );

export const slideIn =
  trigger(
    'slideInAnimation',
    [
      transition(
        ':enter',
        [
          style({ maxHeight: 0 }),
          animate('.3s ease-out',
            style({ maxHeight: 1000 }))
        ]
      ),
      transition(
        ':leave',
        [
          style({ maxHeight: 1000 }),
          animate('.3s ease-in',
            style({  maxHeight: 0 }))
        ]
      )
    ]
  );

export const stepAnimation =
  trigger(
    'stepAnimation',
    [
      transition(
        ':leave',
        [
          style({ position: 'absolute', width: '100%', transform: 'translateX(0%)', opacity: 1 }),
          animate('.3s ease-in', style({ position: 'absolute', width: '100%', transform: 'translateX(-100%)', opacity: 0}))
        ]
      ),
      transition(
        ':enter',
        [
          style({ position: 'absolute', width: '100%', transform: 'translateX({{ startingPoint }})', opacity: 0}),
          animate('.3s .3s ease-out', style({ position: 'absolute', width: '100%', transform: 'translateX(0%)', opacity: 1 }))
        ],
        {params: { startingPoint: '100%'}}
      )

    ]
  );
