import { NgModule } from '@angular/core';
import {TogglePasswordDirective} from './toggle-password.directive';



@NgModule({
  declarations: [TogglePasswordDirective],
  imports: [
  ],
  exports: [
    TogglePasswordDirective
  ]
})
export class DirectivesModule { }
