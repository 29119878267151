import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[togglePassword]'
})
export class TogglePasswordDirective {

  private _shown = false;
  private _show = '<i class="fa fa-eye-slash"></i>';
  private _hide = '<i class="fa fa-eye"></i>';

  constructor(private el: ElementRef) {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    span.className = 'password-toggle';
    span.innerHTML = this._show;
    span.addEventListener('click', () => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = this._hide;
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = this._show;
    }
  }
}
