import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '@click/common';
import {FormBuilder, FormGroup, FormArray, Validators, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UpsellsService} from '../../services/upsells.service';
import {UpsellModel} from '../../models/upsell.model';
import {SailingTypeModel} from '../../models/sailing-type.type';
import { inOut } from '../../app.animations';
import {MembershipLevelTypes} from "../../models/membership-level.type";
import {MemberModel} from "../../models/member.model";
import {SettingsModel} from "../../models/settings.model";
import {Chargable} from "../../shared/chargable";
import {StripeCard} from "stripe-angular";
import Token = stripe.Token;
import {SubaccountModel} from '../../models/subaccount.model';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
  animations: [inOut]
})
export class UpgradeComponent extends Chargable implements OnInit {

  // @TODO: not sure how to set existing form values or validate when the fields are looped
  // @TODO: not sure how selected upsells should be stored in subaccount model
  @ViewChild('failSwal') private failSwal: SwalComponent;

  public ready = false;
  public form: FormGroup;
  public formSubmitted = false;
  public formProcessing = false;
  public formProcessed = false;
  public apiErrors: any;
  public formStep = 1;
  public sailingTypes = SailingTypeModel.get();
  public feesActive = false;
  public cardError = false;
  public cardPaymentError: string = null;
  public upsells: UpsellModel[];
  public membershipTypes = MembershipLevelTypes.get();
  public stripeOptions: any;
  public stripeElementsOptions: any;
 // public matrix: any;

  // use membership model here?
  public member: MemberModel;
  public originalData: MemberModel;
  @ViewChild('stripeCard', { static: false }) stripeCard: StripeCard;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<UpgradeComponent>,
    protected upsellService: UpsellsService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super();
    this.member = data.member;
  }

  ngOnInit(): void {
    this.upsellService.getUpsells().then(upsells => {
      this.upsells = upsells;
      this.form = this.formBuilder.group({
        membership_level: [this.member.membership_level, [Validators.required]],
        id: [this.member._id.$oid, [Validators.required]],
        first_name: [this.member.first_name, [Validators.required]],
        last_name: [this.member.last_name, [Validators.required]],
        golf_enrolled: [{value: this.member.golf_enrolled, disabled: this.member.golf_enrolled}, []],
        tennis_enrolled: [{value: this.member.tennis_enrolled, disabled: this.member.tennis_enrolled}, []],
        subaccounts: this.formBuilder.array([]),
      });


      if (this.member.membership_level === 'Junior Membership') {
        // can't downgrade to sustaining
        this.membershipTypes.splice(0, 1);
      }

      if (this.member.membership_level === 'House Membership') {
        this.membershipTypes.splice(0, 2);
      }

      const control = this.form.controls.subaccounts as FormArray;

      for (let i = 0; i < this.member.subaccounts.length; i++) {
        const config = {
          id: [this.member.subaccounts[i].id, [Validators.required]],
          first_name: [this.member.subaccounts[i].first_name, []],
          last_name: [this.member.subaccounts[i].last_name, []],
          golf_enrolled: [{value: this.member.subaccounts[i].golf_enrolled, disabled: this.member.subaccounts[i].golf_enrolled}, []],
          tennis_enrolled: [{value: this.member.subaccounts[i].tennis_enrolled, disabled: this.member.subaccounts[i].tennis_enrolled}, []],
          tennis_code: [this.member.subaccounts[i].tennis_code, []],
          sailing_enrolled: [this.member.subaccounts[i].sailing_enrolled, []],
          sailing_type: [this.member.subaccounts[i].sailing_type, []],
          is_adult: [this.member.subaccounts[i].is_adult, []],
          dob: [this.member.subaccounts[i].dob, []],
        };

        control.push(this.formBuilder.group(config));
      }

      this.ready = true;
    });

    // @ts-ignore
    this.originalData = new MemberModel(JSON.parse(JSON.stringify(this.member)));


    this.stripeElementsOptions = {
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400'
        }
      ]
    }

    this.stripeOptions = {
      style: {
        base: {
          fontSize: '16px',
          color: '#222288',
          fontWeight: '400',
          fontFamily:
            'Montserrat, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#222288',
          },
        },
        invalid: {
          color: '#cb2e2e'
        }
      }
    }
  }

  hadUpsell(accountId: string, upsellId: string) {
    if (accountId === 'primary') {
      for (let a = 0; a < this.originalData.upsells.length; a++) {
        if (this.originalData.upsells[a].id === upsellId) {}
        return true;
      }

      return false;
    }

    for (let a = 0; a < this.originalData.subaccounts.length; a++) {
      if (accountId === this.originalData.subaccounts[a].id && this.originalData.subaccounts[a].upsells) {
        for (let b = 0; b < this.originalData.subaccounts[a].upsells.length; a++) {
          if (this.originalData.subaccounts[a].upsells[b].id === upsellId) {}
          return true;
        }
      }
    }
    return false;
  }

  hasUpsell(accountId: string, upsellId: string) {
    if (accountId === 'primary') {
      for (let a = 0; a < this.member.upsells.length; a++) {
        if (this.member.upsells[a].id === upsellId) {}
        return true;
      }

      return false;
    }

    for (let a = 0; a < this.member.subaccounts.length; a++) {
      if (accountId === this.member.subaccounts[a].id && this.member.subaccounts[a].upsells) {
        for (let b = 0; b < this.member.subaccounts[a].upsells.length; a++) {
          if (this.member.subaccounts[a].upsells[b].id === upsellId) {}
          return true;
        }
      }
    }
    return false;
  }

  toggleUpsell(accountId: string, id: string) {
    console.log('toggled');
    if (accountId === 'primary') {
      for (let a = 0; a < this.member.upsells.length; a++) {
        if (this.member.upsells[a].id === id) {
          this.member.upsells.splice(a, 1);
          return;
        }
      }

      const upsell = this.getUpsell(id);
      upsell.id = upsell._id.$oid;
      delete upsell._id;
      this.member.upsells.push(upsell);
      return;
    }

    for (let b = 0; b < this.member.subaccounts.length; b++) {
      if (this.member.subaccounts[b].id === accountId) {
        for (let a = 0; a < this.member.subaccounts[b].upsells.length; a++) {
          if (this.member.subaccounts[a].upsells[a].id === id) {
            this.member.subaccounts[a].upsells.splice(a, 1);
            return;
          }
        }

        const upsell = this.getUpsell(id);
        upsell.id = upsell._id.$oid;
        delete upsell._id;
        this.member.subaccounts[b].upsells.push(upsell);
        return;
      }
    }
  }

  getUpsell(id) {
    for (let a = 0; a < this.upsells.length; a++) {
      if (this.upsells[a]._id.$oid === id) {
        return JSON.parse(JSON.stringify(this.upsells[a]));
      }
    }
  }

  getSubAccountFormControls() {
    // @ts-ignore
    return this.form.controls.subaccounts.controls;
  }

  getUpsellFormControls() {
    // @ts-ignore
    return this.form.controls.upsells.controls;
  }

  nextStep() {
    if (this.getSubtotal()) {
      this.formStep++;
      this.slideTo(this.formStep);
    } else {
      this.dialogRef.close();
    }
  }

  slideTo(step) {
    setTimeout(() => {
      const el = document.getElementById('step' + step);
      el.scrollIntoView({behavior: 'smooth'});
    }, 350);
  }

  submit() {
    this.formSubmitted = true;

    if (!this.form.valid || this.formProcessing) {
      return;
    }

    this.formProcessing = true;
    this.stripeCard.createToken().then((response: Token) => {
      const props = JSON.parse(JSON.stringify(this.member));
      const matrix = this.getChargeMatrix();
      props.membership_level = this.form.value.membership_level;
      props.golf_enrolled = this.form.value.golf_enrolled;
      props.tennis_enrolled = this.form.value.tennis_enrolled;

      for (let s = 0; s < matrix.primary.charges.length; s++) {
        switch (matrix.primary.charges[s].name) {
          case 'Golf': {
            props.golf_fee = matrix.primary.charges[s].amount;
            break;
          }
          case 'Tennis': {
            props.tennis_fee = matrix.primary.charges[s].amount;
            break;
          }
          case 'Sailing': {
            props.sailing_fee = matrix.primary.charges[s].sailing_fee;
            break;
          }
        }
      }

      for (let a = 0; a < props.subaccounts.length; a++) {
        for (let b = 0; b < this.form.value.subaccounts.length; b++) {
          if (this.form.value.subaccounts[b].id === props.subaccounts[a].id) {
            if (this.form.value.subaccounts[b].golf_enrolled === false || this.form.value.subaccounts[b].golf_enrolled === true) {
              props.subaccounts[b].golf_enrolled = this.form.value.subaccounts[b].golf_enrolled;
              if (props.subaccounts[b].golf_enrolled) {
                for (let z = 0; z < matrix.subaccounts.length; z++) {
                  if (matrix.subaccounts[z].id === props.subaccounts[a].id) {
                    for (let y = 0; y < matrix.subaccounts[z].charges.length; y++) {
                      if (matrix.subaccounts[z].charges[y].name === 'Golf') {
                        props.subaccounts[b].golf_fee = matrix.subaccounts[z].charges[y].amount;
                      }
                    }
                  }
                }
              }
            }

            if (this.form.value.subaccounts[b].tennis_enrolled === false || this.form.value.subaccounts[b].tennis_enrolled === true) {
              props.subaccounts[a].tennis_enrolled = this.form.value.subaccounts[b].tennis_enrolled;
              if (props.subaccounts[b].tennis_enrolled) {
                for (let z = 0; z < matrix.subaccounts.length; z++) {
                  if (matrix.subaccounts[z].id === props.subaccounts[a].id) {
                    for (let y = 0; y < matrix.subaccounts[z].charges.length; y++) {
                      if (matrix.subaccounts[z].charges[y].name === 'Tennis') {
                        props.subaccounts[b].tennis_fee = matrix.subaccounts[z].charges[y].amount;
                      }
                    }
                  }
                }
              }
            }

            if (this.form.value.subaccounts[b].sailing_enrolled === false || this.form.value.subaccounts[b].sailing_enrolled === true) {
              props.subaccounts[a].sailing_enrolled = this.form.value.subaccounts[b].sailing_enrolled;
              if (props.subaccounts[b].sailing_enrolled) {
                for (let z = 0; z < matrix.subaccounts.length; z++) {
                  if (matrix.subaccounts[z].id === props.subaccounts[a].id) {
                    for (let y = 0; y < matrix.subaccounts[z].charges.length; y++) {
                      if (matrix.subaccounts[z].charges[y].name === 'Sailing') {
                        props.subaccounts[b].sailing_fee = matrix.subaccounts[z].charges[y].amount;
                      }
                    }
                  }
                }
              }
            }
            if (this.form.value.subaccounts[b].sailing_type ) {
              props.subaccounts[a].sailing_type = this.form.value.subaccounts[b].sailing_type;
            }
          }
        }
      }

      props.token = response.id;
      props.total = this.getGrandTotal(); // we need a real total here
      props.sales_tax += this.getSalesTax();
      props.credit_card_fee += this.getCreditCardFees();
      props.member_id = this.form.value.id;
      props.stripe_data = response;

      this.apiService.post('membership/charge-card', props).then((result) => {
        this.dialogRef.close({event: 'UPDATE', data: this.member});
      }).catch((error) => {
        console.error(error);
        this.failSwal.fire();
      }).finally(() => {
        this.formProcessing = false;
      });
    }).catch((error: any) => {
      this.formProcessing = false;
      this.failSwal.fire();
    });
  }

  getChargeMatrix() {
    const charges = {
      primary: {
        name: `${this.member.first_name} ${this.member.last_name}`,
        charges: []
      },
      subaccounts: []
    };

    if (this.form.value.membership_level != null && this.originalData.membership_level !== this.form.value.membership_level) {
      let fee = 0;
      let taxable = false;
      switch (this.form.value.membership_level) {
        case 'House Membership': {
          fee = SettingsModel.houseMembershipFee;
          taxable = SettingsModel.houseMembershipFeeTaxable;
          break;
        }
        case 'Junior Membership': {
          fee = SettingsModel.juniorMembershipFee;
          taxable = SettingsModel.juniorMembershipFeeTaxable;
          break;
        }
        case 'Sustaining Membership': {
          fee = SettingsModel.sustainingMembershipFee;
          taxable = SettingsModel.sustainingMembershipFeeTaxable;
          break;
        }
      }

      charges.primary.charges.push({
        name: this.form.value.membership_level,
        amount: fee,
        taxable
      });
    }

    let tennisAdults = this.getHowManyAdultsHadTennisToStart();

    if (this.form.value.golf_enrolled != null && this.originalData.golf_enrolled !== this.form.value.golf_enrolled) {
      charges.primary.charges.push({
        name: 'Golf',
        amount: SettingsModel.golfFee,
        taxable: SettingsModel.golfFeeTaxable,
      });
    }

    if (this.form.value.tennis_enrolled != null && this.originalData.tennis_enrolled !== this.form.value.tennis_enrolled) {
      let fee = 0;

      if (tennisAdults <= 0 ) {
        fee = SettingsModel.tennisFeeFirstAdult;
      } else if (tennisAdults === 1) {
        fee = SettingsModel.tennisFeeSecondAdult;
      } else {
        fee = SettingsModel.tennisFeeAdditionalAdults;
      }

      // add me to the counter!
      tennisAdults++;

      charges.primary.charges.push({
        name: 'Tennis',
        amount: fee,
        taxable: SettingsModel.tennisFeeTaxable,
      });
    }

    for (let a = 0; a < this.upsells.length; a++) {
      if (this.hasUpsell('primary', this.upsells[a]._id.$oid) && ! this.hadUpsell('primary', this.upsells[a]._id.$oid)) {
        charges.primary.charges.push({
          name: this.upsells[a].title,
          amount: this.upsells[a].price,
          taxable: this.upsells[a].taxable
        });
      }
    }

    for (let a = 0; a < this.originalData.subaccounts.length; a++) {
      charges.subaccounts.push({
        name: `${this.originalData.subaccounts[a].first_name} ${this.originalData.subaccounts[a].last_name}`,
        id: `${this.form.value.subaccounts[a].id}`,
        charges: []
      });

      if (this.originalData.subaccounts[a].is_adult) {
        if (this.form.value.subaccounts[a].golf_enrolled != null &&
          this.originalData.subaccounts[a].golf_enrolled !== this.form.value.subaccounts[a].golf_enrolled) {
          charges.subaccounts[a].charges.push({
            name: 'Golf',
            amount: SettingsModel.golfFee,
            taxable: SettingsModel.golfFeeTaxable,
          });
        }

        if (this.form.value.subaccounts[a].tennis_enrolled != null &&
          this.originalData.subaccounts[a].tennis_enrolled !== this.form.value.subaccounts[a].tennis_enrolled) {
          let fee = 0;

          if (tennisAdults <= 0 ) {
            fee = SettingsModel.tennisFeeFirstAdult;
          } else if (tennisAdults === 1) {
            fee = SettingsModel.tennisFeeSecondAdult;
          } else {
            fee = SettingsModel.tennisFeeAdditionalAdults;
          }

          // add me to the counter!
          tennisAdults++;

          charges.subaccounts[a].charges.push({
            name: 'Tennis',
            amount: fee,
            taxable: SettingsModel.tennisFeeTaxable,
          });
        }
      } else {
        if (this.form.value.subaccounts[a].tennis_enrolled != null
          && this.originalData.subaccounts[a].tennis_enrolled !== this.form.value.subaccounts[a].tennis_enrolled) {
          let fee = 0;
          const age = this.originalData.subaccounts[a].getAge();

          if (age >= 5 && age <= 6) {
            // this.tennis_code = 'pt';
            fee = SettingsModel.tennisFee56;
          }

          if (age >= 7 && age <= 12) {
            // this.tennis_code = 'ct';
            fee = SettingsModel.tennisFee712;
          }

          if (age >= 13 && age <= 16) {
            // this.tennis_code = 'yt';
            fee = SettingsModel.tennisFee1316;
          }

          // add me to the counter!
          charges.subaccounts[a].charges.push({
            name: 'Tennis',
            amount: fee,
            taxable: SettingsModel.tennisFeeTaxable,
          });
        }
        if (this.form.value.subaccounts[a].sailing_enrolled != null &&
          this.originalData.subaccounts[a].sailing_enrolled !== this.form.value.subaccounts[a].sailing_enrolled) {
          let fee = 0;

          switch (this.form.value.subaccounts[a].sailing_type) {
            case 'childrens_sailing_group_fee_season': {
              // this.sailing_code = 'cs';
              fee = SettingsModel.childrensSailingGroupFeeSeason;
              break;
            }
            case 'childrens_sailing_group_fee_4_weeks': {
              // this.sailing_code = 'sf';
              fee = SettingsModel.childrensSailingGroupFee4Weeks;
              break;
            }
            case 'childrens_sailing_group_fee_2_weeks': {
              // this.sailing_code = 'st';
              fee = SettingsModel.childrensSailingGroupFee2Weeks;
              break;
            }
          }

          // add me to the counter!
          charges.subaccounts[a].charges.push({
            name: 'Sailing',
            amount: fee,
            taxable: SettingsModel.childrensSailingGroupFeeTaxable,
          });
        }
      }

      for (let b = 0; b < this.upsells.length; b++) {
        if (this.hasUpsell(this.form.value.subaccounts[a].id, this.upsells[b]._id.$oid) && ! this.hadUpsell(this.form.value.subaccounts[a].id, this.upsells[b]._id.$oid)) {
          charges.subaccounts[a].charges.push({
            name: this.upsells[b].title,
            amount: this.upsells[b].price,
            taxable: this.upsells[b].taxable
          });
        }
      }
    }
    //this.matrix = charges;
    return charges;
  }

  getMatrixSubaccounts() {
    const matrix = this.getChargeMatrix();
    return matrix.subaccounts;
  }

  getMatrixPrimaryName() {
    const matrix = this.getChargeMatrix();
    return matrix.primary.name;
  }

  getMatrixPrimaryCharges() {
    const matrix = this.getChargeMatrix();
    return matrix.primary.charges;
  }


  protected calculateTaxAmount(fee, taxable) {
    if (!taxable) {
      return fee;
    }

    return fee * (SettingsModel.salesTaxPercentage / 100);
  }

  getSubtotal() {
    const charges = this.getChargeMatrix();
    let total = 0;

    for (let a = 0; a < charges.primary.charges.length; a++) {
      total += charges.primary.charges[a].amount;
    }
    for (let a = 0; a < charges.subaccounts.length; a++) {
      for (let b = 0; b < charges.subaccounts[a].charges.length; b++) {
        total += charges.subaccounts[a].charges[b].amount;
      }
    }

    return total;
  }

  getTaxableSubtotal() {
    const charges = this.getChargeMatrix();
    let total = 0;

    for (let a = 0; a < charges.primary.charges.length; a++) {
      if (charges.primary.charges[a].taxable) {
        total += charges.primary.charges[a].amount;
      }
    }
    for (let a = 0; a < charges.subaccounts.length; a++) {
      for (let b = 0; b < charges.subaccounts[a].charges.length; b++) {
        if (charges.subaccounts[a].charges[b].taxable) {
          total += charges.subaccounts[a].charges[b].amount;
        }
      }
    }

    return total;
  }

  getSalesTax() {
    return this.getTaxableSubtotal() * (SettingsModel.salesTaxPercentage / 100);
  }

  getCreditCardFees() {
     const subTotal = this.getSubtotal() + this.getSalesTax();
     const stripe = ((subTotal) + .30) / (1 - (2.9 / 100));
     return stripe - subTotal;
  }

  getGrandTotal() {
    return this.getSubtotal() + this.getSalesTax() + this.getCreditCardFees();
  }



  getHowManyAdultsHadTennisToStart() {
    let count = 0;

    if (this.originalData.tennis_enrolled) {
      count++;
    }

    for (let a = 0; a < this.member.subaccounts.length; a++) {
      if (this.member.subaccounts[a].is_adult && this.member.subaccounts[a].tennis_enrolled) {
        count++;
      }
    }

    return count;
  }
}
