<div class="modal modal-wider">
  <div class="modal-close">
    <i class="fal fa-times" mat-dialog-close></i>
  </div>
  <div class="modal-inner">
    <swal
      #failSwal
      title="Oops!"
      text="There was an issue updating your account. Please try again."
      icon="warning"
      [showCancelButton]="false"
      [focusConfirm]="true"
      [confirmButtonText]="'OK'"
    >
    </swal>
    <h1 class="align-center" *ngIf="!ready"><span class="fa fa-spinner fa-spin"></span></h1>
    <div class="row center" *ngIf="ready">
      <div class="col">
        <div class="align-center">
          <h2>Upgrade Membership &amp; Activities</h2>
          <p>Upgrade membership and activities for you and all subaccounts</p>
        </div>
        <div class="form space-top" *ngIf="member">
          <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!formProcessed" novalidate>
            <div class="row">
              <div class="col-42 col-md-48">
                <div class="less-space-bottom">
                  <div class="row v-center">
                    <div class="col-free">
                      <label for="membership_level" id="membership_level">Membership Level</label>
                    </div>
                    <div class="col-free">
                      <div class="select-wrap label-left">
                        <select class="" name="membership_level"[formControl]="form.controls.membership_level">
                          <option *ngFor="let option of membershipTypes" [value]="option">{{option}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="primary-account">
                  <div class="subaccount">
                    <div class="subaccount-inner">
                      <h4>{{ form.value.first_name }} {{ form.value.last_name }} <span class="light">(Primary Account Holder)</span></h4>
                      <div class="row wrap">
                        <div class="col-12 col-sm-18 col-xs-24">
                          <label class="checkbox">
                            <div class="row v-center">
                              <div class="col-free">
                                <input type="checkbox"
                                       formControlName="golf_enrolled"
                                       name="golf_enrolled"
                                       [value]="true"
                                       class="form-check-input"
                                >
                                <span class="custom-checkbox-small"></span>
                              </div>
                              <div class="col-free">
                                <span class="label">Golf</span>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div class="col-12 col-sm-18 col-xs-24">
                          <label class="checkbox">
                            <div class="row v-center">
                              <div class="col-free">
                                <input type="checkbox"
                                       formControlName="tennis_enrolled"
                                       name="tennis_enrolled"
                                       [value]="true"
                                       class="form-check-input"
                                >
                                <span class="custom-checkbox-small"></span>
                              </div>
                              <div class="col-free">
                                <span class="label">Tennis</span>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div class="col-12 col-sm-18 col-xs-24" *ngFor="let upsell of upsells">
                          <label class="checkbox">
                            <div class="row v-center">
                              <div class="col-free">
                                <input type="checkbox"
                                       name="upsell"
                                       [value]="true"
                                       [checked]="hasUpsell('primary', upsell._id.$oid)"
                                       (change)="toggleUpsell('primary', upsell._id.$oid)"
                                       class="form-check-input"
                                >
                                <span class="custom-checkbox-small"></span>
                              </div>
                              <div class="col-free">
                                <span class="label">{{ upsell.title }}</span>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="subaccount" *ngFor="let sub of getSubAccountFormControls() let i = index; let l = last;">
                    <div class="subaccount-inner">
                      <h4>{{ sub.value.first_name }} {{ sub.value.last_name }} <span class="light" *ngIf="sub.value.is_adult">(Adult)</span></h4>
                      <div class="row wrap">
                        <div class="col-12 col-sm-18 col-xs-24" *ngIf="sub.value.is_adult">
                          <label class="checkbox">
                            <div class="row v-center">
                              <div class="col-free">
                                <input type="checkbox"
                                       [formControl]="sub.controls.golf_enrolled"
                                       name="golf_enrolled"
                                       [value]="true"
                                       class="form-check-input"
                                >
                                <span class="custom-checkbox-small"></span>
                              </div>
                              <div class="col-free">
                                <span class="label">Golf</span>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div class="col-12 col-sm-18 col-xs-24">
                          <label class="checkbox">
                            <div class="row v-center">
                              <div class="col-free">
                                <input type="checkbox"
                                       [formControl]="sub.controls.tennis_enrolled"
                                       name="tennis_enrolled"
                                       [value]="true"
                                       class="form-check-input"
                                >
                                <span class="custom-checkbox-small"></span>
                              </div>
                              <div class="col-free">
                                <span class="label">Tennis</span>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div class="col-12 col-sm-18 col-xs-24" *ngFor="let upsell of upsells">
                          <label class="checkbox">
                            <div class="row v-center">
                              <div class="col-free">
                                <input type="checkbox"
                                       [value]="true"
                                       [checked]="hasUpsell(sub.value.id, upsell._id.$oid)"
                                       (change)="toggleUpsell(sub.value.id, upsell._id.$oid)"
                                       class="form-check-input">
                                <span class="custom-checkbox-small"></span>
                              </div>
                              <div class="col-free">
                                <span class="label">{{ upsell.title }}</span>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div class="col-48" *ngIf="!sub.value.is_adult">
                          <label class="checkbox">
                            <div class="row v-center">
                              <div class="col-free">
                                <input type="checkbox"
                                       [formControl]="sub.controls.sailing_enrolled"
                                       name="sailing_enrolled"
                                       [value]="true"
                                       class="form-check-input"
                                >
                                <span class="custom-checkbox-small"></span>
                              </div>
                              <div class="col-free">
                                <span class="label">Sailing</span>
                              </div>
                            </div>
                          </label>
                          <div class="form-control less-space-top" *ngIf="sub.value.sailing_enrolled">
                            <div class="row">
                              <div class="col-free contains-tooltip">
                                <p class="less-space align-left">What type of lesson do you want to enroll {{sub.value.first_name}} in?</p>
                              </div>
                            </div>
                            <div class="row v-center less-space-bottom no-space-xs-bottom" *ngFor="let type of sailingTypes">
                              <div class="col-free">
                                <label class="checkbox">
                                  <div class="row v-center">
                                    <div class="col-free">
                                      <input type="radio"
                                             name="sailing_type"
                                             [value]="type.id"
                                             class="form-check-input"
                                             [formControl]="sub.controls.sailing_type">
                                      <span class="custom-checkbox custom-checkbox-circle"></span>
                                    </div>
                                    <div class="col-free">
                                      <span class="label">{{type.label}}</span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row center space-top">
              <div class="col-free align-center">
                <button type="button" class="btn btn-md btn-purple" (click)="nextStep()">
                  Upgrade <i class="fa fa-chevron-circle-right margin-left"></i>
                </button>

              </div>
            </div>

            <div [@inOutAnimation] *ngIf="formStep >= 2" class="step-two" id="step2">

              <div class="dues">
                <div class="dues-inner">
                  <div class="row center">
                    <div class="col align-center">
                      <h5>Activities Dues</h5>
                    </div>
                  </div>

                  <div class="dues-section" *ngIf="getMatrixPrimaryCharges().length">
                    <div class="row">
                      <div class="col">
                        <h6>{{ getMatrixPrimaryName() }}</h6>
                      </div>
                    </div>
                    <div class="row" *ngFor="let item of getMatrixPrimaryCharges()">
                      <div class="col">
                        <p>{{item.name}}</p>
                      </div>
                      <div class="col">
                        <p class="mobile-bold">{{ item.amount | currency}} <span *ngIf="item.taxable"> + Tax </span></p>
                      </div>
                    </div>
                  </div>
                  <div class="dues-section" *ngFor="let account of getMatrixSubaccounts()">
                    <div *ngIf="account.charges.length">
                      <div class="row">
                        <div class="col">
                          <h6>{{ account.name }}</h6>
                        </div>
                      </div>
                      <div class="row" *ngFor="let item of account.charges">
                        <div class="col">
                          <p>{{item.name}}</p>
                        </div>
                        <div class="col">
                          <p class="mobile-bold">{{ item.amount | currency}} <span *ngIf="item.taxable"> + Tax </span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dues-section">
                    <div class="row">
                      <div class="col">
                        <h6>Totals</h6>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p>Subtotal:</p>
                      </div>
                      <div class="col">
                        <p class="mobile-bold">{{getSubtotal() | currency}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p>Taxes:</p>
                      </div>
                      <div class="col">
                        <p class="mobile-bold">{{getSalesTax() | currency}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p>Credit Card Fees:</p>
                      </div>
                      <div class="col">
                        <p class="mobile-bold">{{getCreditCardFees() | currency}}</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <p class="bold">Total:</p>
                      </div>
                      <div class="col">
                        <p class="bold">{{getGrandTotal() | currency}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>






              <div class="content space-top">
                <div class="row center has-caret-down">
                  <div class="col-free">
                    <i class="fa fa-caret-down"></i>
                  </div>
                </div>
              </div>
              <div class="form-control">
                <h3 class="align-center">Payment</h3>
              </div>
              <div>
                <div class="payment-form less-space-top">
                  <div class="row wrap">
                    <div class="col align-center">
                      <p>Please fill out the form below to complete your payment of </p>
                    </div>
                    <div class="col">
                      <div *ngIf="cardInvalidError" style="color:red">
                        {{ cardInvalidError.message }}
                      </div>
                      <stripe-card #stripeCard
                                   [createOptions]="stripeElementsOptions"
                                   [options]="stripeOptions"
                                   (catch) = "onStripeError($event)"
                                   [(complete)] = "cardDetailsFilledOut"
                                   [(invalid)] = "cardInvalidError"
                                   (cardMounted) = "cardCaptureReady"
                                   (paymentMethodChange) = "setPaymentMethod($event)"
                                   (tokenChange) = "setStripeToken($event)"
                                   (sourceChange) = "setStripeSource($event)"
                      ></stripe-card>
                    </div>
                  </div>
                </div>
              </div>
              <div class="button-col align-center">
                <button class="btn btn-md btn-purple full-width" type="submit">
                  <span *ngIf="!formProcessing">Complete My Membership<i class="fa fa-chevron-circle-right margin-left"></i></span>
                  <span *ngIf="formProcessing"><i class="fa fa-spin fa-cog"></i></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
