import {Component} from '@angular/core';
import {ApiService} from '@click/common';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {inOut, stepAnimation} from '../app.animations';
import {MembershipFormComponent} from './membership-form.component';
import {UpsellsService} from '../services/upsells.service';
import {LoginModalComponent} from "../modals/login-modal/login-modal.component";
import Swal, {SweetAlertResult} from "sweetalert2";


@Component({
  selector: 'app-membership-form-new',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss'],
  animations: [inOut, stepAnimation]
})
export class MembershipFormNewComponent extends MembershipFormComponent {


  private emailDebounce: number;

  constructor(apiService: ApiService, formBuilder: FormBuilder, dialog: MatDialog, upsellService: UpsellsService) {
    super(apiService, formBuilder, dialog, upsellService);

    this.form.get('email').valueChanges.subscribe((email) => {

      clearTimeout(this.emailDebounce);
      this.emailDebounce = setTimeout(() => {
        if (email) {
          this.checkingForEmail = true;
          this.apiService.post('membership/member-exists', {email}).then((result) => {
            this.emailInUse = result.exists;

            if (this.emailInUse) {
              Swal.fire({
                title: 'You have an Account!',
                text: 'You should login to your account. If you do not have your password, you can reset it.',
                showCancelButton: true,
                confirmButtonText: 'Login',
                cancelButtonText: 'Cancel'
              }).then((confimation: SweetAlertResult) => {
                if (confimation.value) {
                  this.doLogin();
                }
              });
            }
          }).catch((error) => {
            console.error(error);
          }).finally(() => {
            this.checkingForEmail = false;
          });
        }
      }, 1500);
    });
  }

  doLogin() {
    const dialogRef = this.dialog.open(LoginModalComponent, {panelClass: 'dialog-no-padding'});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
