import {SubaccountModel} from "./subaccount.model";
import {MembershipLevel} from "./membership-level.type";
import {SailingType} from "./sailing-type.type";
import {DiscountModel} from "./discount.model";
import {UpsellModel} from "./upsell.model";

export class MemberModel {
  private _discountAmount: number = null;

  public _id: {
    $oid: string
  };
  public first_name: string;
  public last_name: string;
  public saltaire_address: string;
  public saltaire_phone: string;
  public winter_address: string;
  public winter_address_two: string;
  public winter_city: string;
  public winter_state: string;
  public winter_zip: string;
  public winter_phone: string;
  public is_renter: boolean;
  public owner_name: string;
  public email: string;
  public subaccounts: SubaccountModel[] = [];
  public membership_level: MembershipLevel;
  public total = 0;
  public membership_fee = 0;
  public restaurant_season_minimum_fee = 0;
  public capital_assessment_fee = 0;
  public initiation_fee = 0;
  public credit_card_fee = 0;
  public sales_tax = 0;
  public golf_enrolled: boolean;
  public golf_fee = 0;
  public tennis_enrolled: boolean;
  public tennis_fee = 0;
  public sailing_enrolled: boolean;
  public sailing_fee = 0;
  public sailing_type: SailingType;
  public cocktail_party: boolean;
  public cocktail_party_adults: number;
  public will_mail_payment = false;
  public second_year_member: boolean;
  public discounts: DiscountModel[];
  public membership_year: number;
  public upsells: UpsellModel[] = [];



  constructor(data: any) {
    for (const prop in data) {
      this[prop] = data[prop];
    }

    for (let i = 0; i < this.subaccounts.length; i++) {
      this.subaccounts[i] = new SubaccountModel(this.subaccounts[i]);
    }
  }

  hasAccountCredit() {
    return this.getAccountCredit() > 0;
  }

  getAccountCredit() {
    if (this._discountAmount !== null) {
      return this._discountAmount;
    }
    let total = 0;
    if (this.discounts) {
      for (let i = 0; i < this.discounts.length; i++) {
        if (this.discounts[i].amount_used < this.discounts[i].amount) {
          total += this.discounts[i].amount;
        }
      }
    }

    this._discountAmount = total;
    return this._discountAmount;
  }


}
