<div class="modal">
  <div class="modal-close">
    <i class="fal fa-times" mat-dialog-close></i>
  </div>
  <div class="modal-inner">
    <div class="row center">
      <div class="col align-center">
        <h2>Edit - {{ subaccount.first_name }} {{ subaccount.last_name }}</h2>
        <div class="form less-space-top">
          <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!formProcessed" novalidate>
            <div class="form-control less-space-top">
              <input placeholder="First Name"
                     type="text"
                     required
                     [formControl]="form.controls.first_name"
                     [ngClass]="{ 'is-invalid': form.controls.first_name.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.first_name.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="first_name"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="Last Name"
                     type="text"
                     required
                     [formControl]="form.controls.last_name"
                     [ngClass]="{ 'is-invalid': form.controls.last_name.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.last_name.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="last_name"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="Email"
                     type="email"
                     required
                     pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                     [formControl]="form.controls.email"
                     [ngClass]="{ 'is-invalid': form.controls.email.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.email.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="email"></click-api-error>
            </div>
            <div class="form-control less-space-top">
              <label class="checkbox">
                <div class="row v-center">
                  <div class="col-free">
                    <input type="checkbox"
                           [value]="true"
                           class="form-check-input"
                           [formControl]="form.controls.is_adult">
                    <span class="custom-checkbox-small"></span>
                  </div>
                  <div class="col-free">
                    <span class="label">{{editableModel.first_name}} is an Adult</span>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-control less-space-top" *ngIf="editableModel.is_adult">
              <label class="checkbox">
                <div class="row v-center">
                  <div class="col-free">
                    <input type="checkbox"
                           class="form-check-input"
                           name="charge_account_permitted"
                           [formControl]="form.controls.charge_account_permitted">
                    <span class="custom-checkbox-small"></span>
                  </div>
                  <div class="col-free">
                    <span class="label">Allow {{editableModel.first_name}} to have a sub-account under his/her own name at the restaurant?</span>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-control" *ngIf="!editableModel.is_adult">
              <mat-form-field class="full-width"(click)="dob.open()">
                <input matInput [formControl]="form.controls.dob" [matDatepicker]="dob"
                       (focus)="dob.open()"  placeholder="Date of Birth" disabled>
                <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                <mat-datepicker #dob disabled="false"></mat-datepicker>
              </mat-form-field>
             <p>{{editableModel.first_name}} is <span class="bold">{{editableModel.getAge()}} years old.</span></p>
            </div>
            <div class="button-col align-center">
              <button class="btn btn-md btn-purple" type="submit">
                <span *ngIf="!formProcessing">Save<i class="fa fa-chevron-circle-right margin-left"></i></span>
                <span *ngIf="formProcessing"><i class="fa fa-spin fa-cog"></i></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
