import { Component } from '@angular/core';
import {ApiService, AuthenticatedUserModel} from '@click/common';
import {SettingsModel} from './models/settings.model';
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public isIE = false;
  private settings: SettingsModel;

  constructor(private apiService: ApiService, private router: Router) {
    this.isIE = /msie\s/i.test(window.navigator.userAgent);

    // Since the settings are needed app wide, get them at the top level component.
    // Since the settings model is static, we should never need to fetch them again
    this.apiService.get('settings/get-settings').then((settings: any) => {
      this.settings = new SettingsModel(settings);
    });

    const user = new AuthenticatedUserModel();
    if (user.isValid()) {
      this.router.navigate(['', 'dashboard']);
    }
  }
}
