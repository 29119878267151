<section class="membership-choices" *ngIf="!optionHasBeenSelected && (!authedUser || !authedUser.isValid())">
  <div class="container">
    <div class="row center button-row wrap-xs">
      <div class="col-free button-col col-xs-48">
        <a (click)="newMemberChoice()" class="btn btn-lg btn-purple">I am a new member</a>
      </div>
      <div class="col-free button-col col-xs-48">
        <a (click)="existingMemberChoice()" class="btn btn-lg btn-purple">I am an existing member</a>
      </div>
    </div>
  </div>
</section>
<div *ngIf="isNewMember || authedUser.isValid()">
<section class="content form" id="form">
  <div class="container border-top">
    <div #spacer></div>
    <h1 class="align-center" *ngIf="loading"><i class="fa fa-spinner fa-spin"></i></h1>
    <div class="row space-between md-center" *ngIf="model && !loading">
      <div class="col-27 col-md-40 col-sm-46 form hide-for-print">
        <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!formProcessed" novalidate>
          <div class="step step-1" id="step1" *ngIf="step >= 1" [@inOutAnimation]>
            <div class="row center has-caret-down">
              <div class="col-free">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div class="row">
              <div class="col align-center">
                <div class="instructions row center">
                  <div class="col-30 col-lg-38 col-md-48">
                    <p class="no-space">Please only enter one family member's name in this field. All other family members will be added subsequently.</p>
                  </div>
                </div>
                <div class="form-control less-space-top">
                  <input placeholder="First Name"
                         type="text"
                         [formControl]="form.controls.first_name"
                         [ngClass]="{ 'is-invalid': form.controls.first_name.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.first_name.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="first_name"></click-api-error>
                </div>
                <div class="form-control">
                  <input placeholder="Last Name" type="text"
                         [formControl]="form.controls.last_name"
                         [ngClass]="{ 'is-invalid': form.controls.last_name.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.last_name.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="last_name"></click-api-error>
                </div>
                <div class="form-control">
                  <input placeholder="Email" type="email"
                         [formControl]="form.controls.email"
                         [ngClass]="{ 'is-invalid': form.controls.email.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.email.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="email"></click-api-error>
                  <p *ngIf="checkingForEmail"><i class="fa fa-spinner fa-spin"></i> Checking for an existing account...</p>
                </div>
                <div class="form-control" *ngIf="form.controls.password">
                  <div class="has-password-toggle relative">
                    <input type="password"
                           togglePassword
                           [formControl]="form.controls.password"
                           placeholder="Password"
                           [ngClass]="{ 'is-invalid': form.controls.password.errors && formSubmitted}" />
                    <div [@inOutAnimation] *ngIf="form.controls.password.errors && formSubmitted" class="invalid-feedback">
                      <p class="form-error" *ngIf="form.controls.password.errors.required">Password is required</p>
                      <p class="form-error" *ngIf="form.controls.password.errors.minlength">Password must be at least 8 characters</p>
                      <click-api-error [apiErrors]="apiErrors" key="password"></click-api-error>
                    </div>
                  </div>
                </div>
                <div class="form-control" *ngIf="form.controls.password">
                  <div class="has-password-toggle relative">
                    <input type="password"
                           togglePassword
                           placeholder="Confirm Password"
                           [formControl]="form.controls.confirmPassword"
                           [ngClass]="{ 'is-invalid': form.controls.confirmPassword.errors && form.controls.confirmPassword.touched}" />
                  </div>
                  <div *ngIf="form.controls.confirmPassword.errors && form.controls.confirmPassword.touched">
                    <p class="form-error" *ngIf="form.controls.confirmPassword.errors.mustMatch">Passwords must match</p>
                  </div>
                  <div [@inOutAnimation] *ngIf="form.controls.confirmPassword.errors && formSubmitted" class="invalid-feedback">
                    <p class="form-error" *ngIf="form.controls.confirmPassword.errors.required">Confirm Password is required</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col align-center space-top">
                <h4>Saltaire Address</h4>
                <div class="form-control less-space-top">
                  <input placeholder="Saltaire Address" type="text" [formControl]="form.controls.saltaire_address"
                         [ngClass]="{ 'is-invalid': form.controls.saltaire_address.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.saltaire_address.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="saltaire_address"></click-api-error>
                </div>
                <div class="form-control">
                  <input placeholder="Saltaire Phone" [mask]="getPhoneMask()" type="text" [formControl]="form.controls.saltaire_phone"
                         [ngClass]="{ 'is-invalid': form.controls.saltaire_phone.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.saltaire_phone.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="saltaire_phone"></click-api-error>
                </div>
              </div>
            </div>
          </div>
          <div class="step step-2" id="step2" *ngIf="step >= 2" [@inOutAnimation]>
            <div class="row center has-caret-down">
              <div class="col-free">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <h4 class="align-center">Winter Address</h4>
            <div class="form-control">
              <input placeholder="Address" type="text" [formControl]="form.controls.winter_address"
                     [ngClass]="{ 'is-invalid': form.controls.winter_address.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_address.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="winter_address"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="Address 2" type="text" [formControl]="form.controls.winter_address_two"
                     [ngClass]="{ 'is-invalid': form.controls.winter_address_two.errors && formSubmitted }">
              <click-api-error [apiErrors]="apiErrors" key="winter_address_two"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="City" type="text" [formControl]="form.controls.winter_city"
                     [ngClass]="{ 'is-invalid': form.controls.winter_city.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_city.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="winter_city"></click-api-error>
            </div>
            <div class="row space-between wrap-xs">
              <div class="col-24 col-xs-48">
                <div class="form-control">
                  <input placeholder="State" type="text" [formControl]="form.controls.winter_state"
                         [ngClass]="{ 'is-invalid': form.controls.winter_state.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_state.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="winter_state"></click-api-error>
                </div>
              </div>
              <div class="col-24 col-xs-48">
                <div class="form-control">
                  <input placeholder="Zip Code" [mask]="'99999'" type="text" [formControl]="form.controls.winter_zip"
                         [ngClass]="{ 'is-invalid': form.controls.winter_zip.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_zip.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="winter_zip"></click-api-error>
                </div>
              </div>
            </div>
            <div class="form-control">
              <input placeholder="Winter Phone" type="text" [mask]="getPhoneMask()" [formControl]="form.controls.winter_phone"
                     [ngClass]="{ 'is-invalid': form.controls.winter_phone.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_phone.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="winter_phone"></click-api-error>
            </div>
          </div>
          <div class="step step-3" id="step3" *ngIf="step >= 3" [@inOutAnimation]>
            <div class="row center">
              <div class="col-free">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div class="row center contains-tooltip" [ngClass]="{ 'button-invalid': form.controls.membership_level.errors && formSubmitted }">
              <div class="col-32 col-sm-40 col-xs-48 align-center">
                <h4>Please Select Your Basic Dues</h4>
                <div class="form-control relative">
                  <button type="button" class="btn btn-lg btn-white-purple"
                          [ngClass]="{'active': model.membership_level == 'House Membership' }"
                          (click)="setMembershipLevel('House Membership')">Regular Membership</button>
                  <ng-template #HouseMemberTooltip>
                    <h2>Regular Membership</h2>
                    <p>Regular Members and the members of their households are eligible for all the privileges of membership in the Saltaire Yacht Club. Only
                      Regular members and their households may sign up for Tennis, Sailing, or Golf.</p>
                  </ng-template>
                  <i class="far fa-question-circle is-tooltip" [tooltip]="HouseMemberTooltip" content-type="template"></i>
                </div>
                <div class="form-control relative">
                  <button type="button" class="btn btn-lg btn-white-purple"
                          [ngClass]="{'active': model.membership_level == 'Junior Membership' }"
                          (click)="setMembershipLevel('Junior Membership')">Junior Membership</button>
                  <ng-template #JuniorMemberTooltip>
                    <h2>Junior Membership</h2>
                    <p>Any person aged 21 through 28, resident in Saltaire, whose family also resides in Saltaire but does not
                      hold Club membership. A Junior Member shall be eligible for House privileges only, exclusive of such restrictions
                      as the Board may impose. A Junior membership shall be given on an individual basis in any family or household.
                      Junior members are not eligible for Tennis, Sailing or Golf.</p>
                  </ng-template>
                  <i class="far fa-question-circle is-tooltip" [tooltip]="JuniorMemberTooltip" content-type="template"></i>
                </div>
                <div class="form-control relative">
                  <button type="button" class="btn btn-lg btn-white-purple"
                          [ngClass]="{'active': model.membership_level == 'Sustaining Membership' }"
                          (click)="setMembershipLevel('Sustaining Membership')">Sustaining Membership</button>
                  <ng-template #SustainingMemberTooltip>
                    <h2>Sustaining Membership</h2>
                    <p>A Regular Member not in residence in Saltaire. A Sustaining membership may be indefinitely renewed, and a decision
                      by such member to rejoin as a Regular Member shall not subject said member to an initiation fee if said person's
                      Sustaining membership has been uninterruptedly maintained. </p>
                  </ng-template>
                  <i class="far fa-question-circle is-tooltip" [tooltip]="SustainingMemberTooltip" content-type="template"></i>
                  <input type="hidden" [formControl]="form.controls.membership_level" [(ngModel)]="model.membership_level">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.membership_level.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="membership_level"></click-api-error>
                </div>
                <p class="disclaimer" *ngIf="model.membership_level === 'House Membership'">
                  <span class="green bold">Regular Membership Selected</span>  |  Regular Members and the members
                  of their households are eligible for all the privileges of membership in the Saltaire Yacht Club. Only
                  Regular members and their households may sign up for Tennis, Sailing, or Golf.
                </p>
                <p class="disclaimer" *ngIf="model.membership_level === 'Junior Membership'">
                  <span class="green bold">Junior Membership Selected</span>  |  Any person aged 21 through 28, resident
                  in Saltaire, whose family also resides in Saltaire but does not hold Club membership. A Junior Member
                  shall be eligible for House privileges only, exclusive of such restrictions as the Board may impose. A
                  Junior membership shall be given on an individual basis in any family or household. Junior members are
                  not eligible for Tennis, Sailing or Golf.
                </p>
                <p class="disclaimer" *ngIf="model.membership_level === 'Sustaining Membership'">
                  <span class="green bold">Sustaining Membership Selected</span>  |  A Regular Member not in residence in
                  Saltaire. A Sustaining membership may be indefinitely renewed, and a decision by such member to rejoin
                  as a Regular Member shall not subject said member to an initiation fee if said person's Sustaining
                  membership has been uninterruptedly maintained.
                </p>
              </div>
            </div>
            <div class="form-control align-center" *ngIf="model.membership_level === 'House Membership' && model.owes_initiation">
              <div class="row center" id="member-toggle">
                <div class="col-free align-center relative contains-tooltip">
                  <h4>Initiation Fee? <i class="far fa-question-circle is-tooltip" [tooltip]="InitiationFee" content-type="template"></i></h4>
                  <ng-template #InitiationFee>
                    <h2>Initiation Fee</h2>
                    <p class="no-space">The Initiation Fee is a one time charge applied to new members. The fee is waived for the first year, it is paid
                      during the second year of membership.
                    </p>
                  </ng-template>
                </div>
              </div>
              <div class="row v-center center" [ngClass]="{ 'radio-invalid': form.controls.second_year_member.errors && formSubmitted }">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center center">
                      <div class="col-free">
                        <input type="radio"
                               name="second_year_member"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.second_year_member">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Yes</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="second_year_member"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.second_year_member">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">No</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row center" *ngIf="model.second_year_member">
              <div class="col-32 col-sm-40 col-xs-48 align-center">
                <p class="disclaimer">The Initiation Fee is a one time charge applied to new members. The fee is waived for the first year, it is paid
                  during the second year of membership.
                </p>
              </div>
            </div>
          </div>
          <div class="step step-4" id="step4" *ngIf="step >= 4" [@inOutAnimation]>
            <div class="row center">
              <div class="col-free">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div class="form-control align-center">
              <div class="less-space-bottom">
                <h4>Are you renting the property at {{model.saltaire_address}}?</h4>
              </div>
              <div class="row v-center center"  [ngClass]="{ 'radio-invalid': form.controls.is_renter.errors && formSubmitted }">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center center">
                      <div class="col-free">
                        <input type="radio"
                               name="is_renter"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.is_renter">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Yes</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="is_renter"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.is_renter">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">No</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="row center" *ngIf="form.controls.is_renter.errors && formSubmitted">
                <div class="col-free align-center">
                  <p class="form-error" [@inOutAnimation]>Field is required</p>
                </div>
              </div>
              <click-api-error [apiErrors]="apiErrors" key="is_renter"></click-api-error>
            </div>
            <div class="form-control less-space-top" *ngIf="model.is_renter">
              <input placeholder="Property Owner's Name" type="text"
                     [ngClass]="{ 'is-invalid': form.controls.owner_name.errors && formSubmitted }"
                     [formControl]="form.controls.owner_name">
              <click-api-error [apiErrors]="apiErrors" key="owner_name"></click-api-error>
            </div>
          </div>
          <div class="step step-5" id="step5" *ngIf="step >= 5" [@inOutAnimation]>
            <div class="row center">
              <div class="col-free">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <!--<div class="form-control align-center">
              <h4>Attending the annual opening cocktail party?</h4>
              <div class="row center">
                <div class="col-42 col-sm-40 col-xs-48">
                  <h6>Please see calendar</h6>
                  <p class="disclaimer">Please note the Opening Cocktail Party is not dinner. it is <span class="bold">ONLY</span> for
                    adult family members over 18. Please no children and no guests.</p>
                </div>
              </div>
              <div class="row v-center center" [ngClass]="{ 'radio-invalid': form.controls.cocktail_party.errors && formSubmitted }">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="cocktail_party"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.cocktail_party">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Yes</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="cocktail_party"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.cocktail_party">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">No</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="row center">
                <div class="col-free align-center">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.cocktail_party.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="cocktail_party"></click-api-error>
                </div>
              </div>
            </div>-->
            <!--<div class="form-control less-space-top" *ngIf="model.cocktail_party">
              <input placeholder="Number of Adults Attending"
                     [mask]="'999'"
                     type="text"
                     [ngClass]="{ 'is-invalid': form.controls.cocktail_party_adults.errors && formSubmitted }"
                     [formControl]="form.controls.cocktail_party_adults">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.cocktail_party_adults.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="cocktail_party_adults"></click-api-error>
            </div>-->
            <div class="form-control space-top align-center">
              <div class="row center">
                <div class="col-free align-center relative contains-tooltip">
                  <h4>{{ model.first_name }} {{model.last_name }} Golf Membership?</h4>
                  <ng-template #Golf>
                    <h2>Golf Membership</h2>
                    <p>The SYC is a member of the MGA (Metropolitan Golf Association). As a golf member you will be able to submit scores and maintain an official handicap.</p>
                  </ng-template>
                  <i class="far fa-question-circle is-tooltip" [tooltip]="Golf" content-type="template"></i>
                </div>
              </div>
              <p>{{ model.golf_fee | currency }}</p>
              <div class="row v-center center" *ngIf="model.membership_level !== 'House Membership'">
                <div class="col-free">
                  <p>Sorry, only House Members can enroll in golf.</p>
                </div>
              </div>
              <div class="row v-center center"
                   *ngIf="model.membership_level === 'House Membership'"
                   [ngClass]="{ 'radio-invalid': form.controls.golf_enrolled.errors && formSubmitted }">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="golf_enrolled"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.golf_enrolled">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Yes</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="golf_enrolled"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.golf_enrolled">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">No</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="row center">
                <div class="col-free align-center">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.golf_enrolled.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="golf_enrolled"></click-api-error>
                </div>
              </div>
            </div>
            <div class="form-control align-center space-top">
              <div class="div row center">
                <div class="col-free relative contains-tooltip">
                  <h4>{{ model.first_name }} {{model.last_name }} Tennis Membership?</h4>
                  <ng-template #Tennis>
                    <h2>Tennis Membership</h2>
                    <p><!--Placeholder tennis membership copy.--></p>
                  </ng-template>
                  <i class="far fa-question-circle is-tooltip" [tooltip]="Tennis" content-type="template"></i>
                </div>
              </div>
              <p>{{ model.tennis_fee | currency }}</p>
              <div class="row v-center center" *ngIf="model.membership_level !== 'House Membership'">
                <div class="col-free">
                  <p>Sorry, only House Members can enroll in tennis.</p>
                </div>
              </div>
              <div class="row v-center center"
                   *ngIf="model.membership_level === 'House Membership'"
                   [ngClass]="{ 'radio-invalid': form.controls.tennis_enrolled.errors && formSubmitted }">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="tennis_enrolled"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.tennis_enrolled">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Yes</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="tennis_enrolled"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.tennis_enrolled">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">No</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="row center">
                <div class="col-free align-center">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.tennis_enrolled.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="tennis_enrolled"></click-api-error>
                </div>
              </div>
            </div>
          </div>
          <div class="step step-6" id="step6" *ngIf="step >= 6" [@inOutAnimation]>
            <div class="row center">
              <div class="col-free">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div class="row center">
              <div class="col align-center">
                <h4 class="align-center">Would you like to add any additional family members?</h4>
                <p class="disclaimer">Please list the names of ALL family members included under your membership below (including
                  spouses of children, etc., where applicable). Include the DATE OF BIRTH of each family member under 21. Indicate
                  individuals over 21 who are permitted to set up their own sub-account at the Club. Members are ultimately responsible
                  for all charges by family members, including on subaccounts. Also indicate those activities in which each member
                  participates such as Sailing, Tennis, and Golf.</p>
              </div>
            </div>
            <ng-container *ngFor="let sub of form.controls.subaccounts.value" [@inOutAnimation]>
              <app-subaccount
                [@inOutAnimation]
                [membershipLevel]="model.membership_level"
                [model]="sub" mode="static"
                (modelDeleted)="removeSubAccount($event)"
                (modelChanged)="subAccountSaved($event)"></app-subaccount>
            </ng-container>
            <div class="align-center space-bottom">
              <button type="button" class="btn btn-sm btn-white-green" (click)="addSubAccount()">Add <span *ngIf="form.controls.subaccounts.value.length">Another</span><i class="fa fa-plus-circle margin-left"></i></button>
              <ng-container *ngIf="addSubAccountActive" [@inOutAnimation]>
                <app-subaccount
                  [@inOutAnimation] mode="add"
                  [model]="addSubaccountModel"
                  [membershipLevel]="model.membership_level"
                  (modelCanceled)="cancelAddSubAccount()"
                  (modelChanged)="subAccountSaved($event)"></app-subaccount>
              </ng-container>
            </div>
          </div>
          <div class="step step-7" id="step7" *ngIf="step == 7" [@inOutAnimation]>
            <div class="row center">
              <div class="col-free">
                <i class="fa fa-caret-down"></i>
              </div>
            </div>
            <div class="form-control">
              <h4 class="align-center">Payment <!--Method--></h4>
              <!--<div class="row v-center center">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="will_mail_payment"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.will_mail_payment">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Credit Card</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="will_mail_payment"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.will_mail_payment">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Check</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="row center" *ngIf="form.controls.will_mail_payment.errors && formSubmitted">
                <div class="col-free align-center">
                  <p class="form-error align-center" [@inOutAnimation]>Field is required</p>
                </div>
              </div>
              <click-api-error [apiErrors]="apiErrors" key="will_mail_payment"></click-api-error>-->
            </div>
            <div *ngIf="model.will_mail_payment === false">
              <div class="payment-form less-space-top">
                <div class="row wrap">
                  <div class="col align-center">
                    <p>Please fill out the form below to complete your payment of {{model.getTotal() | currency}}</p>
                  </div>
                  <div *ngIf="cardInvalidError" style="color:red">
                    {{ cardInvalidError.message }}
                  </div>
                  <div class="form-control full-width">
                    <stripe-card #stripeCard
                                 [createOptions]="stripeElementsOptions"
                                 [options]="stripeOptions"
                                 (catch) = "onStripeError($event)"
                                 [(complete)] = "cardDetailsFilledOut"
                                 [(invalid)] = "cardInvalidError"
                                 (cardMounted) = "cardCaptureReady"
                                 (paymentMethodChange) = "setPaymentMethod($event)"
                                 (tokenChange) = "setStripeToken($event)"
                                 (sourceChange) = "setStripeSource($event)"
                    ></stripe-card>
                  </div>

                 <!-- <div class="col-32 col-xs-48 form-control">
                    <input placeholder="Credit Card Number" type="text" [formControl]="form.controls.credit_card_number"
                           [ngClass]="{ 'is-invalid': form.controls.credit_card_number.errors && formSubmitted }">
                    <p class="form-error" [@inOutAnimation] *ngIf="form.controls.credit_card_number.errors && formSubmitted">Field is required</p>
                    <click-api-error [apiErrors]="apiErrors" key="credit_card_number"></click-api-error>
                  </div>
                  <div class="col-16 col-xs-48 form-control">
                    <input placeholder="Card SEC Number" type="text" [formControl]="form.controls.credit_card_sec"
                           [ngClass]="{ 'is-invalid': form.controls.credit_card_sec.errors && formSubmitted }">
                    <p class="form-error" [@inOutAnimation] *ngIf="form.controls.credit_card_sec.errors && formSubmitted">Field is required</p>
                    <click-api-error [apiErrors]="apiErrors" key="credit_card_sec"></click-api-error>
                  </div>-->
                </div>
<!--                <pre>{{ stripeOptions | json }}</pre>-->
                <!--<div class="row wrap-xs">
                  <div class="col-16 col-xs-48 text-align-xs form-control padding-top">
                    <p class="no-space align-center">Expiration Date</p>
                  </div>
                  <div class="col-16 col-xs-48 form-control">
                    <input placeholder="Expiration Month" type="text" [mask]="'9?9'" [formControl]="form.controls.credit_card_month"
                           [ngClass]="{ 'is-invalid': form.controls.credit_card_month.errors && formSubmitted }">
                    &lt;!&ndash;<click-dropdown [options]="monthsDropdownOptions"
                                    [ngClass]="{ 'dropdown-invalid': form.controls.credit_card_month.errors && formSubmitted }"
                                    [element]="form.controls.credit_card_month">
                    </click-dropdown>&ndash;&gt;
                    <p class="form-error" [@inOutAnimation] *ngIf="form.controls.credit_card_month.errors && formSubmitted">Field is required</p>
                    <click-api-error [apiErrors]="apiErrors" key="credit_card_month"></click-api-error>
                  </div>
                  <div class="col-16 col-xs-48 form-control">
                    <input placeholder="Expiration Year" [mask]="''" type="text" [formControl]="form.controls.credit_card_year"
                           [ngClass]="{ 'is-invalid': form.controls.credit_card_year.errors && formSubmitted }">
                    &lt;!&ndash;<click-dropdown [options]="yearsDropdownOptions"
                                    [ngClass]="{ 'dropdown-invalid': form.controls.credit_card_year.errors && formSubmitted }"
                                    [element]="form.controls.credit_card_year">
                    </click-dropdown>&ndash;&gt;
                    <p class="form-error" [@inOutAnimation] *ngIf="form.controls.credit_card_year.errors && formSubmitted">4 digit year required</p>
                    <click-api-error [apiErrors]="apiErrors" key="credit_card_year"></click-api-error>
                  </div>
                </div>-->
              </div>
            </div>
            <div class="less-space-top form-control">
              <button class="btn btn-xl btn-purple" type="submit" (click)="submit()" [disabled]="formProcessing">
                <span *ngIf="!formProcessing">Complete My Membership <i class="fa fa-chevron-circle-right margin-left"></i></span>
                <span *ngIf="formProcessing"><i class="fa fa-spinner fa-spin"></i></span>
              </button>
              <p class="form-error" [@inOutAnimation] *ngIf="cardPaymentError && formSubmitted">{{cardPaymentError}}</p>
              <p class="form-error" [@inOutAnimation] *ngIf="cardError && formSubmitted">Your credit card is invalid or expired.</p>
              <p class="form-error" [@inOutAnimation] *ngIf="!form.valid && formSubmitted">Your submission contains one or more errors. Please correct them before completing the form.</p>
            </div>
          </div>
          <div class="row center step step-buttons" *ngIf="step <= 6 && !emailInUse" [@inOutAnimation]>
            <div class="col-free" *ngIf="step > 1 && !addSubaccountModel">
              <button class="btn btn-sm btn-purple" type="button" (click)="back()"><i class="fa fa-chevron-circle-left margin-right"></i> Back</button>
            </div>
            <div class="col-free" *ngIf="step < 7 && !addSubaccountModel">
              <button class="btn btn-sm btn-purple" [disabled]="checkingForEmail" type="button" (click)="next()">Next <i class="fa fa-chevron-circle-right margin-left"></i></button>
            </div>
          </div>
        </form>
        <div class="step step-8" *ngIf="formProcessed" [@inOutAnimation]>
          <div class="align-center form-success">
            <h1>Thank You!</h1>
            <p *ngIf="!model.will_mail_payment">You're all done!</p>
            <div class="space-top">
              <button class="btn btn-lg btn-purple" type="button" (click)="printReceipt()">Print Receipt</button>
            </div>
            <div *ngIf="model.will_mail_payment" class="check-information">
              <div class="row center">
                <div class="col-free">
                  <i class="fa fa-caret-down"></i>
                </div>
              </div>
              <div class="align-center">
                <h3 class="space">Please mail check to:</h3>
                <h2>Saltaire Yacht Club</h2>
                <h4>130 New Hyde Park Road</h4>
                <h4>P.O. Box 267</h4>
                <h4 class="space">Franklin Square, NY 11010</h4>
                <h3 class="space">Questions?</h3>
                <h4>Please email inquiries to:</h4>
                <h4><a href="mailto:mindi@texpak.com">mindi@texpak.com</a></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fees-toggle" [ngClass]="{ active: feesActive }" (click)="toggleFeesCol()">
        <div class="row v-center">
          <div class="col-free">
            <h4>{{ model.getTotal() | currency }}</h4>
          </div>
          <div class="col-free">
            <i class="fa fa-chevron-left"></i>
          </div>
        </div>
      </div>
      <div class="col-17 fees" stickyThing [spacer]="spacer" marginTop="30" [ngClass]="{ active: feesActive }" *ngIf="model">
        <div class="row show-for-print space-bottom no-border">
          <div class="col align-center">
            <h1>Receipt for {{ model.first_name }} {{ model.last_name }}</h1>
          </div>
        </div>
        <div class="fees-inner relative">
          <div class="row">
            <div class="col-32">
              <p>Membership</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.membership_fee | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.restaurant_season_minimum_fee">
            <div class="col-32">
              <!--<ng-template #RestMinTooltip>
                <h2>Restaurant Minimum</h2>
                <p>Some placeholder text for the tooltip.</p>
              </ng-template>-->
              <p>Restaurant Minimum <!--<i class="fa fa-question-circle is-tooltip" [tooltip]="RestMinTooltip" content-type="template"></i>--></p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.restaurant_season_minimum_fee | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.capital_assessment_fee">
            <div class="col-32">
              <!--<ng-template #CapitalAssessment>
                <h2>Capital Assessment</h2>
                <p>Placeholder capital assessment text.
                </p>
              </ng-template>-->
              <p>Capital Assessment <!--<i class="fa fa-question-circle is-tooltip" [tooltip]="CapitalAssessment" content-type="template"></i>--></p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.capital_assessment_fee | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.initiation_fee">
            <div class="col-32">
              <ng-template #InitiationFee>
                <h2>Initiation Fee</h2>
                <p>The Initiation Fee is a one time charge applied to new members. The fee is waived for the first year, it is paid
                  during the second year of membership.
                </p>
              </ng-template>
              <p>Initiation <i class="fa fa-question-circle is-tooltip" [tooltip]="InitiationFee" content-type="template"></i></p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.initiation_fee | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.golf_fee">
            <div class="col-32">
              <p>Golf <i class="fa fa-question-circle is-tooltip" [tooltip]="GolfSide" content-type="template"></i></p>
              <ng-template #GolfSide>
                <h2>Golf Membership</h2>
                <p>The SYC is a member of the MGA (Metropolitan Golf Association). As a golf member you will be able to submit scores and maintain an official handicap.</p>
              </ng-template>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.golf_fee | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.tennis_fee">
            <div class="col-32">
              <p>Tennis</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.tennis_fee | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.sailing_fee">
            <div class="col-32">
              <p>Sailing</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.sailing_fee | currency}}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-32">
              <p>Sales Tax</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.sales_tax | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.credit_card_fee">
            <div class="col-32">
              <p>Processing Fee</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.credit_card_fee | currency}}</span>
            </div>
          </div>
          <div class="row no-border" *ngIf="model.subaccounts.length">
            <div class="col-32">
              <p>Family Members</p>
            </div>
            <div class="col-16 align-right">
              &nbsp;
            </div>
          </div>
          <div class="row no-border less-padding wrap" *ngFor="let subaccount of model.subaccounts">
            <div class="col-32">
              <p class="indented white">{{ subaccount.first_name}} {{ subaccount.last_name }}</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{subaccount.getTotal() | currency}}</span>
            </div>
          </div>
          <div class="row" *ngIf="model.getDiscount()">
            <div class="col-32">
              <p>Discount</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.getDiscount() | currency}}</span>
            </div>
          </div>
          <div class="row total-row">
            <div class="col-32">
              <p class="bold white">Total</p>
            </div>
            <div class="col-16 align-right">
              <span class="price">{{model.getTotal() | currency}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>

