export class SettingsModel {
  private static $houseMembershipFee: number;
  private static $houseMembershipFeeTaxable: boolean;
  private static $initiationFee: number;
  private static $initiationFeeTaxable: boolean;
  private static $juniorMembershipFee: number;
  private static $juniorMembershipFeeTaxable: boolean;
  private static $sustainingMembershipFee: number;
  private static $sustainingMembershipFeeTaxable: boolean;
  private static $restaurantSeasonMinimum: number;
  private static $restaurantSeasonMinimumTaxable: boolean;
  private static $capitalAssessmentFee: number;
  private static $capitalAssessmentFeeTaxable: boolean;
  private static $childrensSailingGroupFeeSeason: number;
  private static $childrensSailingGroupFee4Weeks: number;
  private static $childrensSailingGroupFee2Weeks: number;
  private static $childrensSailingGroupFeeTaxable: boolean;
  private static $golfFee: number;
  private static $golfFeeTaxable: boolean;
  private static $tennisFeeFirstAdult: number;
  private static $tennisFeeSecondAdult: number;
  private static $tennisFeeAdditionalAdults: number;
  private static $tennisFee1316: number;
  private static $tennisFee712: number;
  private static $tennisFee56: number;
  private static $tennisFeeTaxable: boolean;
  private static $salesTaxPercentage: number;
  private static $creditCardSurchargePercentage: number;
  private static $dailyNotificationEmails: string[];

  constructor(data?: any) {
    if (data) {
      delete data._id;
      for (const prop in data) {
        const wordedProp = prop.replace(/_/g, ' ');
        let formattedProp = wordedProp.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');

        formattedProp = formattedProp.replace(/_/g, '');

        SettingsModel['$' + formattedProp] = data[prop];
      }
    }
  }

  static get houseMembershipFee(): number {
    return this.$houseMembershipFee;
  }

  static get houseMembershipFeeTaxable(): boolean {
    return this.$houseMembershipFeeTaxable;
  }

  static get initiationFee(): number {
    return this.$initiationFee;
  }

  static get initiationFeeTaxable(): boolean {
    return this.$initiationFeeTaxable;
  }

  static get juniorMembershipFee(): number {
    return this.$juniorMembershipFee;
  }

  static get juniorMembershipFeeTaxable(): boolean {
    return this.$juniorMembershipFeeTaxable;
  }

  static get sustainingMembershipFee(): number {
    return this.$sustainingMembershipFee;
  }

  static get sustainingMembershipFeeTaxable(): boolean {
    return this.$sustainingMembershipFeeTaxable;
  }

  static get restaurantSeasonMinimum(): number {
    return this.$restaurantSeasonMinimum;
  }

  static get restaurantSeasonMinimumTaxable(): boolean {
    return this.$restaurantSeasonMinimumTaxable;
  }

  static get capitalAssessmentFee(): number {
    return this.$capitalAssessmentFee;
  }

  static get capitalAssessmentFeeTaxable(): boolean {
    return this.$capitalAssessmentFeeTaxable;
  }

  static get childrensSailingGroupFeeSeason(): number {
    return this.$childrensSailingGroupFeeSeason;
  }

  static get childrensSailingGroupFee4Weeks(): number {
    return this.$childrensSailingGroupFee4Weeks;
  }

  static get childrensSailingGroupFee2Weeks(): number {
    return this.$childrensSailingGroupFee2Weeks;
  }

  static get childrensSailingGroupFeeTaxable(): boolean {
    return this.$childrensSailingGroupFeeTaxable;
  }

  static get golfFee(): number {
    return this.$golfFee;
  }

  static get golfFeeTaxable(): boolean {
    return this.$golfFeeTaxable;
  }

  static get tennisFeeFirstAdult(): number {
    return this.$tennisFeeFirstAdult;
  }

  static get tennisFeeSecondAdult(): number {
    return this.$tennisFeeSecondAdult;
  }

  static get tennisFeeAdditionalAdults(): number {
    return this.$tennisFeeAdditionalAdults;
  }

  static get tennisFee1316(): number {
    return this.$tennisFee1316;
  }

  static get tennisFee712(): number {
    return this.$tennisFee712;
  }

  static get tennisFee56(): number {
    return this.$tennisFee56;
  }

  static get tennisFeeTaxable(): boolean {
    return this.$tennisFeeTaxable;
  }

  static get salesTaxPercentage(): number {
    return this.$salesTaxPercentage;
  }

  static get creditCardSurchargePercentage(): number {
    return this.$creditCardSurchargePercentage;
  }

  static get dailyNotificationEmails(): string[] {
    return this.$dailyNotificationEmails;
  }
}
