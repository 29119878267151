import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EditSubaccountComponent} from './edit-subaccount/edit-subaccount.component';
import {LoginModalComponent} from './login-modal/login-modal.component';
import {UpgradeComponent} from './upgrade/upgrade.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxMaskModule} from 'ngx-mask';
import {ClickModule} from '@click/common';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {DirectivesModule} from '../directives/directives.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {StripeModule} from 'stripe-angular';
import {EditMainAccountComponent} from './edit-main-account/edit-main-account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  declarations: [EditSubaccountComponent, LoginModalComponent, UpgradeComponent, EditMainAccountComponent, ChangePasswordComponent],
  exports: [
    EditSubaccountComponent,
    LoginModalComponent,
    UpgradeComponent,
    EditMainAccountComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    DirectivesModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ClickModule,
    SweetAlert2Module,
    StripeModule
  ]
})
export class ModalsModule { }
