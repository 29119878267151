import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LoginModalComponent} from '../../modals/login-modal/login-modal.component';
import {Router, Event, NavigationEnd} from '@angular/router';
import {AuthenticatedUserModel} from "@click/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public currentYear = 2020;
  public user: AuthenticatedUserModel;

  constructor(public dialog: MatDialog, private router: Router) {

    const d = new Date();
    this.currentYear = d.getFullYear();
    this.user = new AuthenticatedUserModel();
  }

  logout() {
    this.user.destroyUser();
    this.router.navigate(['']);
  }

  slideToForm() {
    const el = document.getElementById('form');
    el.scrollIntoView({behavior: 'smooth'});
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginModalComponent, {panelClass: 'dialog-no-padding'});
    dialogRef.afterClosed().subscribe(result => {});
  }
}
