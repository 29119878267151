import {SubaccountModel} from './subaccount.model';
import {MembershipLevel} from './membership-level.type';
import {SettingsModel} from './settings.model';
import {SailingType} from './sailing-type.type';
import {DiscountModel} from "./discount.model";
import {ApiService} from '@click/common';

export class MembershipModel {

  /**
   * Constructor
   */
  constructor(
    public first_name?: string,
    public last_name?: string,
    public saltaire_address?: string,
    public saltaire_phone?: string,
    public winter_address?: string,
    public winter_address_two?: string,
    public winter_city?: string,
    public winter_state?: string,
    public winter_zip?: string,
    public winter_phone?: string,
    public is_renter?: boolean,
    public owner_name?: string,
    public email?: string,
    public subaccounts: SubaccountModel[] = [],
    public membership_level: MembershipLevel  = 'House Membership',
    public total = 0,
    public membership_fee = 0,
    public restaurant_season_minimum_fee = 0,
    public capital_assessment_fee = 0,
    public initiation_fee = 0,
    public credit_card_fee = 0,
    public sales_tax = 0,
    public golf_enrolled?: boolean,
    public golf_fee = 0,
    public tennis_enrolled?: boolean,
    public tennis_fee = 0,
    public sailing_enrolled?: boolean,
    public sailing_fee = 0,
    public sailing_type?: SailingType,
    public cocktail_party?: boolean,
    public cocktail_party_adults?: number,
    public will_mail_payment = false,
    public second_year_member?: boolean,
    public discounts?: DiscountModel[],
    public membership_year?: number,
    public credit_card_number?: number,
    public credit_card_month?: number,
    public credit_card_year?: number,
    public credit_card_sec?: number,
    public owes_initiation?: boolean,
  ) {}

  /**
   * Sets/Updates the model
   */
  setData(data: any) {

    for (const prop in data) {
      if (prop !== 'subaccounts') {
       // console.log('Setting', prop, data[prop], data);
        this[prop] = data[prop];
      }
    }

    if (data.subaccounts) {
      let count = 0;

      if (this.tennis_enrolled) {
        count++;
      }

      for (let a = 0; a < data.subaccounts.length; a++) {
        if (!(data.subaccounts[a] instanceof SubaccountModel)) {
          data.subaccounts[a] = new SubaccountModel(data.subaccounts[a]);
        }
        if (data.subaccounts[a].tennis_enrolled) {
          if (data.subaccounts[a].is_adult || data.subaccounts[a].getAge() > 16) {
            count++;
          }
        }

        data.subaccounts[a].setData(data.subaccounts[a], count);
      }

      this.subaccounts = data.subaccounts;

      /*for (let a = 0; a < data.subaccounts.length; a++) {
        const sub = new SubaccountModel(SettingsModel);
        sub.setData(data.subaccounts[a]);

        if (sub.tennis_enrolled) {
          if (sub.is_adult) {
            count++;
            sub.setData(data.subaccounts[a], count); // reset the data with the adult count
          } else if (sub.getAge() > 16) {
            count++;
            sub.setData(data.subaccounts[a], count); // reset the data with the adult count
          }
        }

        subAccounts.push(sub);

      }

      this.subaccounts = subAccounts;*/
    }

    // By default the primary member is an adult,
    // and only kids can enroll in sailing
    this.sailing_enrolled = false;

    this.membership_fee = this.getMembershipFee();
    this.restaurant_season_minimum_fee = this.getRestaurantSeasonMinimumFee();
    this.capital_assessment_fee = this.getCapitalAssessmentFee();
    this.initiation_fee = this.getInitiationFee();
    this.credit_card_fee = this.getCreditCardFee();
    this.sales_tax = this.getSalesTax();
    this.golf_fee = this.getGolfFee();
    this.tennis_fee = this.getTennisFee();
    this.sailing_fee = this.getSailingFee();
    this.total = this.getTotal();
  }

  /**
   * Gets the membership fee
   */
  getMembershipFee(): number {
    switch (this.membership_level) {
      case 'House Membership': {
        return SettingsModel.houseMembershipFee;
      }
      case 'Junior Membership': {
        return SettingsModel.juniorMembershipFee;
      }
      case 'Sustaining Membership': {
        return SettingsModel.sustainingMembershipFee;
      }
    }

    return 0;
  }

  /**
   * Gets the restaurant Season Min
   */
  getRestaurantSeasonMinimumFee(): number {
    switch (this.membership_level) {
      case 'House Membership':
      case 'Junior Membership': {
        return SettingsModel.restaurantSeasonMinimum;
      }
      case 'Sustaining Membership': {
        return 0;
      }
    }

    return this.restaurant_season_minimum_fee;
  }

  /**
   * Gets the capital assessment fee
   */
  getCapitalAssessmentFee(): number {
    switch (this.membership_level) {
      case 'House Membership':
      case 'Junior Membership': {
        return SettingsModel.capitalAssessmentFee;
      }
      case 'Sustaining Membership': {
        return 0;
      }
    }

    return 0;
  }

  /**
   * Gets the initiation fee
   */
  getInitiationFee(): number {
    switch (this.membership_level) {
      case 'House Membership':
      case 'Junior Membership': {
        if (this.second_year_member) {
          return SettingsModel.initiationFee;
        }

        return 0;
      }
      case 'Sustaining Membership': {
        return 0;
      }
    }

    return 0;
  }

  /**
   * Gets the credit card fee
   */
  getCreditCardFee(): number {
    if (this.will_mail_payment) {
      return 0;
    }
    const sub = this.getSubtotal() + this.getSalesTax();

    return  (sub + .30 ) / ( 1 - (2.9 / 100)) - sub;


    return this.getSubtotal() * (SettingsModel.creditCardSurchargePercentage / 100);
  }

  /**
   * Gets the sales tax
   */
  getSalesTax(): number {
    return (this.getTaxableAmount()) * (SettingsModel.salesTaxPercentage / 100);
  }

  /**
   * Gets the golf fee
   */
  getGolfFee(): number {
    if (this.golf_enrolled) {
      return SettingsModel.golfFee;
    }

    return 0;
  }

  /**
   * Gets the tennis fee
   */
  getTennisFee(): number {
    if (this.tennis_enrolled) {
      return SettingsModel.tennisFeeFirstAdult;
    }

    return 0;
  }

  /**
   * Gets the sailing fee
   */
  getSailingFee(): number {
    // By default the primary member is an adult,
    // and only kids can enroll in sailing
    return 0;
  }


  /**
   * Gets the subtotal
   */
  getSubtotal(): number {
    const total = (this.getMembershipFee()
      + this.getRestaurantSeasonMinimumFee()
      + this.getCapitalAssessmentFee()
      + this.getInitiationFee()
      + this.getGolfFee()
      + this.getSailingFee()
      + this.getTennisFee()
      + this.getSubaccountTotal()) - this.getDiscount();
    return total;
  }

  /**
   * Gets the total that is taxable
   */
  getTaxableAmount(): number {
    let taxableAmount = 0;

    if (this.membership_level === 'House Membership' && SettingsModel.houseMembershipFeeTaxable) {
      taxableAmount += this.getMembershipFee();
    } else if (this.membership_level === 'Junior Membership' && SettingsModel.juniorMembershipFeeTaxable) {
      taxableAmount += this.getMembershipFee();
    } else if (this.membership_level === 'Sustaining Membership' && SettingsModel.sustainingMembershipFeeTaxable) {
      taxableAmount += this.getMembershipFee();
    }

    if (SettingsModel.restaurantSeasonMinimumTaxable) {
      taxableAmount += this.getRestaurantSeasonMinimumFee();
    }

    if (SettingsModel.capitalAssessmentFeeTaxable) {
      taxableAmount += this.getCapitalAssessmentFee();
    }

    if (this.owes_initiation && SettingsModel.initiationFeeTaxable) {
      taxableAmount += this.getInitiationFee();
    }

    if (SettingsModel.golfFeeTaxable) {
      taxableAmount += this.getGolfFee();
    }

    if (SettingsModel.tennisFeeTaxable) {
      taxableAmount += this.getTennisFee();
    }

    if (SettingsModel.childrensSailingGroupFeeTaxable) {
      taxableAmount += this.getSailingFee();
    }

    taxableAmount += this.getSubaccountTaxableTotal();
    taxableAmount -= this.getDiscount();

    if (taxableAmount <= 0) {
      return 0;
    }

    return taxableAmount;
  }

  getDiscount() {
    let discount = 0;
    if (this.discounts && this.discounts.length) {
      for (let a = 0; a < this.discounts.length; a++) {
        discount += (this.discounts[a].amount - this.discounts[a].amount_used);
      }
    }

    if (!discount) {
      return 0;
    }

    return discount;
  }
  /**
   * Gets the grand total
   */
  getTotal(): number {
    return (this.getSubtotal() + this.getCreditCardFee() + this.getSalesTax());
  }

  /**
   * Gets the total for all sub accounts
   */
  getSubaccountTotal(): number {
    let total = 0;

    for (let a = 0; a < this.subaccounts.length; a++) {
      total += this.subaccounts[a].getTotal();
    }

    return total;
  }

  getSubaccountTaxableTotal(): number {
    let total = 0;

    for (let a = 0; a < this.subaccounts.length; a++) {
      total += this.subaccounts[a].getTaxableTotal();
    }

    return total;
  }

  /**
   * Gets the number of adults enrolled in tennis
   */
  getNumberOfAdultsEnrolledInTennis(subAccounts: SubaccountModel[]): number {
    let count = 0;

    if (this.tennis_enrolled) {
      count++;
    }

    for (let a = 0; a < subAccounts.length; a++) {
      if (subAccounts[a].tennis_enrolled) {
        if (subAccounts[a].is_adult) {
          count++;
        } else if (subAccounts[a].getAge() > 16) {
          count++;
        }
      }
    }

    return count;
  }
}
