<div class="form form-subaccount content">
  <div *ngIf="mode === 'static'" [@inOutAnimation]>
    <div class="subaccount space-bottom">
      <div class="row center">
        <div class="col-free align-center">
          <h4 class="green">Household Member Added</h4>
        </div>
      </div>
      <div class="row subaccount-row">
        <div class="col">
          <p class="bold">{{model.first_name}} {{model.last_name}}</p>
        </div>
      </div>
      <div class="row subaccount-row">
        <div class="col">
          <p>{{model.email}}</p>
        </div>
      </div>
      <div class="row subaccount-row" *ngIf="model.is_adult">
        <div class="col">
          <p>{{model.first_name}} is an adult</p>
        </div>
      </div>
      <div class="row subaccount-row" *ngIf="!model.is_adult">
        <div class="col">
          <p>{{model.first_name}} is {{model.getAge()}} years old.</p>
        </div>
      </div>
      <div class="row subaccount-row" *ngIf="model.charge_account_permitted">
        <div class="col">
          <p>{{model.first_name}} can charge items to this account</p>
        </div>
      </div>
      <div class="row subaccount-row" *ngIf="model.golf_enrolled">
        <div class="col-32">
          <p>{{model.first_name}} is enrolled in golf for</p>
        </div>
        <div class="col-16 align-right">
          {{model.golf_fee | currency}}
        </div>
      </div>
      <div class="row subaccount-row" *ngIf="model.tennis_enrolled">
        <div class="col-32">
          <p>{{model.first_name}} is enrolled in tennis for</p>
        </div>
        <div class="col-16 align-right">
          <p>{{model.tennis_fee | currency}}</p>
        </div>
      </div>
      <div class="row subaccount-row" *ngIf="model.sailing_enrolled">
        <div class="col-32">
          <p>{{model.first_name}} is enrolled in sailing <span *ngIf="model.sailing_type == 'childrens_sailing_group_fee_season'">(Season)</span>
            <span *ngIf="model.sailing_type == 'childrens_sailing_group_fee_4_weeks'">(4 Weeks)</span>
            <span *ngIf="model.sailing_type == 'childrens_sailing_group_fee_2_weeks'">(2 Weeks)</span> for </p>
        </div>
        <div class="col-16 align-right">
          <p>{{model.sailing_fee | currency}}</p>
        </div>
      </div>
      <div class="row subaccount-row no-border">
        <div class="col-32">
          <p>Total cost for {{model.first_name}}:</p>
        </div>
        <div class="col-16 align-right">
          <p class="bold">{{model.getTotal() | currency}}</p>
        </div>
      </div>
      <div class="row center step-buttons space-top">
        <div class="col-free">
          <button class="btn btn-sm btn-purple" type="button" (click)="edit()"><i class="fa fa-user-edit margin-right"></i> Edit</button>
        </div>
        <div class="col-free">
          <button class="btn btn-sm btn-purple" type="button" [swal]="{
                    title: 'Are you sure?',
                    text: 'You will not be able to undo this.',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    icon: 'warning'}" (confirm)="remove()"
          >Remove <i class="fa fa-minus-circle margin-left"></i></button>
        </div>
      </div>
    </div>
  </div>
  <ng-container [@inOutAnimation] *ngIf="mode !== 'static'">
    <form [formGroup]="form" [ngClass]="{ 'space-bottom': mode === 'edit' }" (ngSubmit)="submit()" novalidate id="sub-form">
      <div class="row">
        <div class="col">
          <div class="align-center">
            <h4 *ngIf="mode === 'add'">Add Family Member</h4>
            <h4 *ngIf="mode === 'edit'">Edit {{editableModel.first_name}} {{editableModel.last_name}}</h4>
          </div>
          <div class="form-control">
            <input placeholder="First Name" type="text" [formControl]="form.controls.first_name"
                   [ngClass]="{ 'is-invalid': form.controls.first_name.errors && formSubmitted }">
            <p class="form-error" [@inOutAnimation] *ngIf="form.controls.first_name.errors && formSubmitted">Field is required</p>
          </div>
          <div class="form-control">
            <input placeholder="Last Name" type="text" [formControl]="form.controls.last_name"
                   [ngClass]="{ 'is-invalid': form.controls.last_name.errors && formSubmitted }">
            <p class="form-error" [@inOutAnimation] *ngIf="form.controls.last_name.errors && formSubmitted">Field is required</p>
          </div>
          <div *ngIf="editableModel.first_name && editableModel.last_name" [@inOutAnimation]>
            <div class="form-control">
              <div class="row v-center wrap-xs">
                <div class="col-free col-xs-48">
                  <span class="label">{{ editableModel.first_name }} {{ editableModel.last_name }} is an:</span>
                </div>
                <div class="col-free offset-2 col-xs-48 xs-offset-0">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="is_adult"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.is_adult"
                               (change)="resetDOB()">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Adult</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2 col-xs-48 xs-offset-0">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="is_adult"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.is_adult"
                               (change)="resetDOB()">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Child</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-control" *ngIf="!editableModel.is_adult">
              <mat-form-field class="full-width"(click)="dob.open()">
                <input matInput [formControl]="form.controls.dob" [matDatepicker]="dob"
                       (focus)="dob.open()"  placeholder="Date of Birth" disabled>
                <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                <mat-datepicker #dob disabled="false"></mat-datepicker>
              </mat-form-field>
              <p>{{editableModel.first_name}} is <span class="bold">{{editableModel.getAge()}} years old.</span></p>
            </div>
            <div class="form-control less-space-bottom">
              <input placeholder="Email" type="email" [formControl]="form.controls.email"
                     [ngClass]="{ 'is-invalid': form.controls.email.errors && formSubmitted }">
              <p *ngIf="!editableModel.is_adult" class="align-left">Use parent's email address for children.</p>
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.email.errors && formSubmitted">Field is required</p>
            </div>
            <div class="form-control" *ngIf="editableModel.is_adult || editableModel.getAge() >= 17">
              <div class="row restaurant-tooltip-row contains-tooltip">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="checkbox"
                               name="charge_account_permitted"
                               class="form-check-input"
                               [formControl]="form.controls.charge_account_permitted">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label block align-left">Allow {{editableModel.first_name}} {{ editableModel.last_name }} a sub-account under his/her own name at the restaurant?</span>
                        <ng-template #ItemCharge>
                          <h2>Subaccounts</h2>
                          <p>Members are ultimately responsible for all charges by family members, including on subaccounts.
                          </p>
                        </ng-template>
                      </div>
                    </div>
                  </label>
                  <i class="far fa-question-circle is-tooltip" [tooltip]="ItemCharge" content-type="template"></i>
                </div>
              </div>
            </div>
            <div class="form-control" *ngIf="membershipLevel === 'House Membership' && editableModel.canEnrollInSailing()">
              <div class="row">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="checkbox"
                               name="sailing_enrolled"
                               class="form-check-input"
                               [formControl]="form.controls.sailing_enrolled">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Enroll {{editableModel.first_name}} In <span class="bold">Sailing Lessons?</span></span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-control less-space-top" *ngIf="editableModel.sailing_enrolled">
              <div class="row">
                <div class="col-free contains-tooltip">
                  <p class="less-space align-left">What type of lesson do you want to enroll {{editableModel.first_name}} in?</p>
                  <!--<ng-template #SailingEnrollment>
                    <h2>Sailing Information</h2>
                    <p>Placeholder sailing text.</p>
                  </ng-template>
                  <i class="far fa-question-circle is-tooltip" [tooltip]="SailingEnrollment" content-type="template"></i>-->
                </div>
              </div>
              <div class="row v-center less-space-bottom no-space-xs-bottom" *ngFor="let type of sailingTypes">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="sailing_type"
                               [value]="type.id"
                               class="form-check-input"
                               [formControl]="form.controls.sailing_type">
                        <span class="custom-checkbox custom-checkbox-circle"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">{{type.label}}</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-control" *ngIf="membershipLevel === 'House Membership' && (editableModel.is_adult || editableModel.getAge() >= 4)">
              <div class="row">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="checkbox"
                               name="charge_account_permitted"
                               class="form-check-input"
                               [formControl]="form.controls.tennis_enrolled">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free contains-tooltip">
                        <span class="label">Is {{editableModel.first_name}} a <span class="bold">Tennis Member?</span></span>
                        <!--<ng-template #TennisEnrollment>
                          <h2>Tennis Information</h2>
                          <p>Placeholder tennis text.
                          </p>
                        </ng-template>
                        <i class="far fa-question-circle is-tooltip" [tooltip]="TennisEnrollment" content-type="template"></i>-->
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-control" *ngIf="membershipLevel === 'House Membership' && (editableModel.is_adult || editableModel.getAge() >= 17)">
              <div class="row golf-tooltip-row contains-tooltip">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="checkbox"
                               name="charge_account_permitted"
                               class="form-check-input"
                               [formControl]="form.controls.golf_enrolled">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free relative">
                        <span class="label">Is {{editableModel.first_name}} a <span class="bold">Golf Member?</span></span>
                        <ng-template #GolfEnrollment>
                          <h2>Golf Membership</h2>
                          <p>The SYC is a member of the MGA (Metropolitan Golf Association). As a golf member you will be able to submit scores and maintain an official handicap.</p>
                        </ng-template>
                      </div>
                    </div>
                  </label>
                  <i class="far fa-question-circle is-tooltip tooltip-absolute" [tooltip]="GolfEnrollment" content-type="template"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row center">
            <div class="col-free col-xs-48">
              <div class="dues less-space-top">
                <div class="row less-space-bottom">
                  <div class="col">
                    <h5 class="uppercase">Activities Dues</h5>
                  </div>
                </div>
                <div class="row space-between" *ngIf="editableModel.sailing_enrolled">
                  <div class="col-free">
                    <p>Sailing Lessons:</p>
                  </div>
                  <div class="col-free align-right">
                    <p>{{editableModel.sailing_fee | currency}}</p>
                  </div>
                </div>
                <div class="row space-between" *ngIf="editableModel.tennis_enrolled">
                  <div class="col-free">
                    <p>Tennis Membership:</p>
                  </div>
                  <div class="col-free align-right">
                    <p>{{editableModel.tennis_fee | currency}}</p>
                  </div>
                </div>
                <div class="row space-between" *ngIf="editableModel.golf_enrolled">
                  <div class="col-free">
                    <p>Golf Fee:</p>
                  </div>
                  <div class="col-free align-right">
                    <p>{{editableModel.golf_fee | currency}}</p>
                  </div>
                </div>
                <div class="row space-between">
                  <div class="col-free">
                    <p class="bold">Total:</p>
                  </div>
                  <div class="col-free align-right">
                    <p class="bold">{{editableModel.getTotal() | currency}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row center step-buttons">
            <div class="col-free">
              <button class="btn btn-sm btn-grey" type="button" (click)="cancel()">Cancel</button>
            </div>
            <div class="col-free">
              <button class="btn btn-sm btn-green" type="submit">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</div>
