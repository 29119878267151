import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '@click/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Subscription} from "rxjs";
import {inOut} from '../../app.animations';
import {MembershipModel} from '../../models/membership.model';
import {MustMatch} from "../../validators/must-match.validators";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [inOut]
})
export class ChangePasswordComponent implements OnInit {

  public form: FormGroup;
  public formSubmitted = false;
  public formProcessing = false;
  public formProcessed = false;
  public apiErrors: any;

  public member: MembershipModel;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.member = data.member;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: [MustMatch('password', 'confirmPassword')]
    });
  }


  submit() {
    this.formSubmitted = true;
    if (!this.form.valid || this.formProcessing) {
      return;
    }
    this.dialogRef.close({event: 'UPDATE', data: this.form.value});
  }

}
