export class Chargable {
  public cardInvalidError;
  public cardCaptureReady = false;
  public cardDetailsFilledOut;
  public extraData = {};

  constructor() {}

  onStripeInvalid( error: Error ) {
    console.log('Validation Error', error);
  }

  onStripeError(error: stripe.Error) {
    console.error('Stripe error', error);
  }

  setPaymentMethod( token: stripe.paymentMethod.PaymentMethod ) {
    console.log('Stripe Payment Method', token);
  }

  setStripeToken( token: stripe.Token ) {
    console.log('Stripe Token', token);
  }

  setStripeSource( source: stripe.Source ) {
    console.log('Stripe Source', source);
  }
}
