import {ApplicationRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SubaccountModel} from '../../models/subaccount.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {SailingTypeModel} from '../../models/sailing-type.type';
import {inOut} from '../../app.animations';
import {MembershipLevel} from '../../models/membership-level.type';

@Component({
  selector: 'app-subaccount',
  templateUrl: './subaccount.component.html',
  styleUrls: ['./subaccount.component.scss'],
  animations: [inOut]
})
export class SubaccountComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  private formChangeSubscription: Subscription;
  public sailingTypes = SailingTypeModel.get();
  public editableModel: SubaccountModel;
  public formSubmitted = false;

  @Input() model: SubaccountModel;
  @Input() mode: string;
  @Input() membershipLevel: MembershipLevel|null;
  @Output() modelChanged: EventEmitter<SubaccountModel> = new EventEmitter<SubaccountModel>();
  @Output() modelCanceled: EventEmitter<any> = new EventEmitter<any>();
  @Output() modelDeleted: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, private applicationRef: ApplicationRef) {
  }

  ngOnInit(): void {
    this.editableModel = new SubaccountModel();
    this.editableModel.setData(this.model);
    /*this.editableModel.first_name = this.model.first_name;
    this.editableModel.last_name = this.model.last_name;
    this.editableModel.email = this.model.email;
    this.editableModel.dob = this.model.dob;
    this.editableModel.is_adult = this.model.is_adult;
    this.editableModel.charge_account_permitted = this.model.charge_account_permitted;
    this.editableModel.sailing_fee = this.model.sailing_fee;
    this.editableModel.sailing_type = this.model.sailing_type;
    this.editableModel.sailing_enrolled = this.model.sailing_enrolled;
    this.editableModel.golf_enrolled = this.model.golf_enrolled;
    this.editableModel.golf_fee = this.model.golf_fee;
    this.editableModel.tennis_enrolled = this.model.tennis_enrolled;
    this.editableModel.tennis_fee = this.model.tennis_fee;
    this.editableModel.adult_count = this.model.adult_count;
    this.editableModel.age = this.model.getAge();*/

    this.form = this.formBuilder.group({
      id: [this.model.id, [Validators.required]],
      first_name: [this.model.first_name, [Validators.required]],
      last_name: [this.model.last_name, [Validators.required]],
      email: [this.model.email, [Validators.required, Validators.email]],
      is_adult: [this.model.is_adult, []],
      charge_account_permitted: [this.model.charge_account_permitted, []],
      dob: [this.model.dob, []],
      golf_enrolled: [this.model.golf_enrolled, []],
      tennis_enrolled: [this.model.tennis_enrolled, []],
      sailing_enrolled: [this.model.sailing_enrolled, []],
      sailing_type: [this.model.sailing_type, []],
    });

    this.formChangeSubscription = this.form.valueChanges.subscribe((values) => {
      this.onFormChange(values);
    });

    setTimeout(() => {
      const el = document.getElementById('sub-form');
      el.scrollIntoView({behavior: 'smooth'});
    }, 100);

  }

  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
  }

  onFormChange(values) {
    this.editableModel.setData(this.form.value, this.model.adult_count);
  }

  resetDOB() {
    this.form.controls.dob.setValue(new Date());
  }

  edit() {
    this.mode = 'edit';
  }

  remove() {
    this.modelDeleted.emit(this.model);
  }

  submit() {
    this.formSubmitted = true;
    if (this.form.valid) {
      this.model.setData(this.form.value, this.model.adult_count);
      this.mode = 'static';
      this.modelChanged.emit(this.model);
    }
  }

  cancel(): void {
    this.mode = 'static';
    this.modelCanceled.emit(true);
  }
}
