import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {RouterModule, Routes} from '@angular/router';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {YesNoPipe} from '../pipes/yes-no.pipe';
import {StripeModule} from 'stripe-angular';

const routes: Routes = [
  {path: '', component: DashboardComponent},
];

@NgModule({
  declarations: [DashboardComponent, YesNoPipe],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SweetAlert2Module,
    StripeModule
  ]
})
export class DashboardModule { }
