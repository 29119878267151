export type SailingType = 'childrens_sailing_group_fee_season'
  | 'childrens_sailing_group_fee_4_weeks'
  | 'childrens_sailing_group_fee_2_weeks';

export class SailingTypeModel {
  static get() {
    return [
      {id: 'childrens_sailing_group_fee_2_weeks', label: '2 Consecutive Weeks'},
      {id: 'childrens_sailing_group_fee_4_weeks', label: '4 Consecutive Weeks'},
      {id: 'childrens_sailing_group_fee_season', label: 'Whole Season'},
    ];
  }
}
