import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService, AuthenticatedUserModel} from '@click/common';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SettingsModel} from '../models/settings.model';
import {MembershipModel} from '../models/membership.model';
import {Subscription} from 'rxjs';
import {MembershipLevelTypes} from '../models/membership-level.type';
import {MatDialog} from '@angular/material/dialog';
import {SubaccountModel} from '../models/subaccount.model';
import {DropdownOptionsModel} from '@click/dropdowns';
import {inOut, stepAnimation} from '../app.animations';
import {SailingTypeModel} from '../models/sailing-type.type';
import {MustMatch} from '../validators/must-match.validators';
import {UpsellsService} from '../services/upsells.service';
import {UpsellModel} from '../models/upsell.model';
import {Chargable} from '../shared/chargable';
import {StripeCard} from 'stripe-angular';
import Token = stripe.Token;
import {LoginModalComponent} from '../modals/login-modal/login-modal.component';




@Component({
  selector: 'app-membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss'],
  animations: [inOut, stepAnimation]
})
export class MembershipFormComponent extends Chargable implements OnInit, OnDestroy {

  public form: FormGroup;
  protected formChangeSubscription: Subscription;
  protected paymentMethodSubscription: Subscription;
  protected cocktailPartySubscription: Subscription;
  public model: MembershipModel;
  public membershipLevels = MembershipLevelTypes.get();
  public sailingTypes = SailingTypeModel.get();
  public step = 1;
  public formSubmitted = false;
  public formProcessing = false;
  public formProcessed = false;
  public apiErrors: any;
  public addSubaccountModel: SubaccountModel;
  public addSubAccountActive = false;
  public months = [];
  public years = [];
  public monthsDropdownOptions: DropdownOptionsModel;
  public yearsDropdownOptions: DropdownOptionsModel;
  public feesActive = false;
  public cardError = false;
  public cardPaymentError: string = null;
  public upsells: UpsellModel[];
  public shouldCheckForEmail = true;
  public checkingForEmail = false;
  public emailInUse = false;
  public authedUser: AuthenticatedUserModel;
  public apiUrl = 'membership/register-new-membership';
  @ViewChild('stripeCard', { static: false }) stripeCard: StripeCard;
  public currentYear: number;
  public stripeOptions: any;
  public stripeElementsOptions: any;
  public loading = false;

  public isNewMember: boolean = false;
  public optionHasBeenSelected: boolean = false;


  constructor(protected apiService: ApiService, protected formBuilder: FormBuilder, public dialog: MatDialog, protected upsellService: UpsellsService ) {
    super();

    const date = new Date();
    this.currentYear = date.getFullYear();

    this.form = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      membership_year: [this.currentYear, [Validators.required]],
      saltaire_address: [null, [Validators.required]],
      saltaire_phone: [null, [Validators.required]],
      winter_address: [null, [Validators.required]],
      winter_address_two: [null, []],
      winter_city: [null, [Validators.required]],
      winter_state: [null, [Validators.required]],
      winter_zip: [null, [Validators.required]],
      winter_phone: [null, [Validators.required]],
      is_renter: [null, [Validators.required]],
      owner_name: [null, []],
      email: [null, [Validators.required]],
      membership_level: ['House Membership', [Validators.required]],
      sales_tax: [null, [Validators.required]],
      golf_enrolled: [null, [Validators.required]],
      tennis_enrolled: [null, [Validators.required]],
      sailing_type: [null, []],
      will_mail_payment: [null, [Validators.required]],
      second_year_member: [false, []],
      subaccounts: [[], []],
      // cocktail_party: [null, [Validators.required]],
      // cocktail_party_adults: [null, []],
      // credit_card_number: [null, [Validators.required]],
      // credit_card_month: [null, [Validators.required]],
      // credit_card_year: [null, [Validators.required, Validators.minLength(4)]],
      // credit_card_sec: [null, [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: [MustMatch('password', 'confirmPassword')]
    });
  }

  async ngOnInit() {
    this.model = new MembershipModel();
    this.model.membership_year = this.currentYear;
    this.form.patchValue(this.model);

    this.formChangeSubscription = this.form.valueChanges.subscribe(() => {
      this.model.setData(this.form.value);
    });

    for(let i = 1; i <= 12; i++) {
      this.months.push({ id: i, label: i});
    }

    const date = new Date();
    const startYear = date.getFullYear();
    const endYear = startYear + 10;
    for (let i = startYear; i <= endYear; i++) {
      this.years.push({id: i, label: i});
    }

    this.monthsDropdownOptions = new DropdownOptionsModel(this.months, {
      placeholder: 'Month',
      caretClass: 'fal fa-chevron-down'
    });

    this.yearsDropdownOptions = new DropdownOptionsModel(this.years, {
      placeholder: 'Year',
      caretClass: 'fal fa-chevron-down'
    });

    this.paymentMethodSubscription = this.form.get('will_mail_payment').valueChanges
      .subscribe(value => {

        /*if (!value) {
          this.form.controls.credit_card_number.setValidators([Validators.required]);
          this.form.controls.credit_card_month.setValidators([Validators.required]);
          this.form.controls.credit_card_year.setValidators([Validators.required]);
          this.form.controls.credit_card_sec.setValidators([Validators.required]);
        } else {
          this.form.controls.credit_card_number.clearValidators();
          this.form.controls.credit_card_month.clearValidators();
          this.form.controls.credit_card_year.clearValidators();
          this.form.controls.credit_card_sec.clearValidators();
        }

        this.form.controls.credit_card_number.updateValueAndValidity();
        this.form.controls.credit_card_month.updateValueAndValidity();
        this.form.controls.credit_card_year.updateValueAndValidity();
        this.form.controls.credit_card_sec.updateValueAndValidity();*/

      });

    if (this.form.get('cocktail_party')) {
      this.cocktailPartySubscription = this.form.get('cocktail_party').valueChanges
        .subscribe(value => {

          if (value) {
            this.form.controls.cocktail_party_adults.setValidators([Validators.required]);
          } else {
            this.form.controls.cocktail_party_adults.clearValidators();
          }

          this.form.controls.cocktail_party_adults.updateValueAndValidity();

        });
    }

    this.upsellService.getUpsells().then(upsells => {
      this.upsells = upsells;
    });

    this.authedUser = new AuthenticatedUserModel();

    if (this.authedUser.isValid()) {
      // this must be a renewal... show loader:
      this.loading = true;
      this.apiService.get('membership/member/' + this.authedUser.id).then(response => {
        // @todo: is this right? i'm receiving an error function setData can't be used on a subaccount
        // const existingData = new MembershipModel(response);
        console.log('RAW', JSON.parse(JSON.stringify(response)));
        if (response.subaccounts) {
          for (let i = 0; i < response.subaccounts.length; i++) {
            if (!response.subaccounts[i].is_adult && !response.subaccounts[i].dob) {
              response.subaccounts[i].tennis_code = '';
              response.subaccounts[i].golf_enrolled = false;
              response.subaccounts[i].golf_fee = 0;
              response.subaccounts[i].tennis_enrolled = false;
              response.subaccounts[i].tennis_fee = 0;
              response.subaccounts[i].sailing_enrolled = false;
              response.subaccounts[i].sailing_fee = 0;
              response.subaccounts[i].sailing_type = '';
              response.subaccounts[i].sailing_code = '';
            }
          }
        }
        this.form.patchValue(response);
        this.model.setData(response);
        this.form.addControl('member_id', new FormControl());
        this.form.controls.member_id.setValue(response._id.$oid);
        this.shouldCheckForEmail = false;
        console.log('model', this.model);
        this.apiUrl = 'membership/upgrade-existing-membership';
        // this.step = 7;
        this.form.setValidators([]);
        this.form.removeControl('password');
        this.form.removeControl('confirmPassword');
        this.loading = false;
      }).catch(error => {
        console.log('err', error);
      });
    }

    this.stripeElementsOptions = {
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400'
        }
      ]
    }

    this.stripeOptions = {
      style: {
        base: {
          fontSize: '16px',
          color: '#222288',
          fontWeight: '400',
          fontFamily:
            'Montserrat, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#222288',
          },
        },
        invalid: {
          color: '#cb2e2e'
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
    this.paymentMethodSubscription.unsubscribe();
  }

  get settings(): SettingsModel {
    return new SettingsModel();
  }

  getPhoneMask() {
    return '(000) 000-0000';
  }



  slideTo(step) {
    setTimeout(() => {
      let el = document.getElementById('step' + step);
      el.scrollIntoView({behavior: 'smooth'});
    }, 350);
  }

  next() {
    this.step++;
    this.slideTo(this.step);
  }

  back() {
    this.step--;
    this.slideTo(this.step);
  }

  toggleFeesCol() {
    this.feesActive = !this.feesActive;
  }

  setMembershipLevel(level) {
    this.model.membership_level = level;
    this.form.controls.membership_level.setValue(level);
    if (level === 'House Membership') {
      setTimeout(() => {
        let el = document.getElementById('member-toggle');
        if (el) {
          el.scrollIntoView({behavior: 'smooth'});
        }
      }, 100);
    } else {
      this.form.controls.golf_enrolled.setValue(false);
      this.form.controls.tennis_enrolled.setValue(false);

      for (let i = 0; i < this.model.subaccounts.length; i++) {
        this.model.subaccounts[i].golf_enrolled = false;
        this.model.subaccounts[i].golf_fee = 0;
        this.model.subaccounts[i].tennis_enrolled = '';
        this.model.subaccounts[i].tennis_fee = 0;
        this.model.subaccounts[i].sailing_enrolled = false;
        this.model.subaccounts[i].sailing_fee = 0;
        this.model.subaccounts[i].total = 0;
      }

      this.model.total = this.model.getTotal();
      this.model.sales_tax = this.model.getSalesTax();
      this.model.credit_card_fee = this.model.getCreditCardFee();
    }
  }

  addSubAccount() {
    const adultCount = this.model.getNumberOfAdultsEnrolledInTennis(this.model.subaccounts) + 1;
    const model = new SubaccountModel();
    model.adult_count = adultCount;

    this.addSubaccountModel = model;
    this.addSubAccountActive = true;

  }

  subAccountSaved(model: SubaccountModel) {
    if (!this.form.controls.subaccounts.value.length) { // we have none, must be an add
      this.form.controls.subaccounts.value.push(model);
      this.model.setData(this.form.value);
      this.cancelAddSubAccount();
      return;
    }

    for (let i = 0; i < this.form.controls.subaccounts.value.length; i++) {
      if (this.form.controls.subaccounts.value[i].id === model.id) {
        this.form.controls.subaccounts.value[i] = model;
        this.model.setData(this.form.value);
        this.cancelAddSubAccount();
        return;
      }
    }

    this.form.controls.subaccounts.value.push(model);
    this.model.setData(this.form.value);
    this.cancelAddSubAccount();
  }

  cancelAddSubAccount() {
    this.addSubAccountActive = false;
    this.addSubaccountModel = null;
  }


  removeSubAccount(model: SubaccountModel) {
    for (let a = 0; a < this.form.controls.subaccounts.value.length; a++) {
      if (this.form.controls.subaccounts.value[a].id === model.id) {
        this.form.controls.subaccounts.value.splice(a, 1);
        break;
      }
    }
  }

  newMemberChoice() {
    this.optionHasBeenSelected = true;
    this.isNewMember = true;
  }

  existingMemberChoice() {
    const dialogRef = this.dialog.open(LoginModalComponent,
      {
        panelClass: ['dialog-no-padding', 'dialog-height-auto'],
      }
    );

  }

  printReceipt() {
    window.print();
  }

  scrollToTopError(className: string) {
    const items = document.getElementsByClassName(className);
    if (items.length > 0) {
      const scrollToY = items[0].getBoundingClientRect().top + window.pageYOffset - 56;
      window.scrollTo({ top: scrollToY, behavior: 'smooth'});
    }
  }

  submit() {
    this.formSubmitted = true;
    console.log('this form', this.form);
    if (!this.form.valid || this.formProcessing) {
      this.scrollToTopError('form-error');
      return;
    }

    this.formProcessing = true;
    this.apiErrors = false;
    this.cardError = false;
    this.cardPaymentError = null;

    const postable = JSON.parse(JSON.stringify(this.model));
    delete postable.$settings;

    postable.membership_fee = this.model.getMembershipFee();
    postable.restaurant_season_minimum_fee = this.model.getRestaurantSeasonMinimumFee();
    postable.capital_assessment_fee = this.model.getCapitalAssessmentFee();
    postable.initiation_fee = this.model.getInitiationFee();
    postable.credit_card_fee = this.model.getCreditCardFee();
    postable.sales_tax = this.model.getSalesTax();
    postable.golf_fee = this.model.getGolfFee();
    postable.tennis_fee = this.model.getTennisFee();
    postable.sailing_fee = this.model.getSailingFee();
    postable.discount_applied = this.model.getDiscount();
    postable.total = this.model.getTotal();
    postable.membership_year = this.currentYear;

    console.log('postable', postable);

    for (let i = 0; i < postable.subaccounts.length; i++) {
      delete postable.subaccounts[i].settings;
    }

    this.stripeCard.createToken().then((token: Token) => {
      if (!token) {
        this.formProcessing = false;
        return;
      }
      console.log('token created', token);
      postable.token = token.id;
      postable.stripe_data = token;
      this.apiService.post(this.apiUrl, postable).then((response) => {
        this.formProcessed = true;
        console.log('Created', response);
      }).catch((error) => {
        console.error('PAY ERROR', error, error.error.detail);
        this.cardPaymentError = error.error.detail;
        this.apiErrors = error;
        this.scrollToTopError('click-api-errors');
      }).finally(() => {
        console.log('done');
        this.formProcessing = false;
      });
    });
  }
}
