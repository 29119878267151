import {SettingsModel} from './settings.model';
import {SailingType} from './sailing-type.type';
import {Hash} from '@click/common';
import {UpsellModel} from "./upsell.model";

export class SubaccountModel {

  public removeIfEmpty = false;
  public id: string = Hash.get();
  public tennis_code: string;
  public total: number;
  public first_name: string;
  public last_name: string;
  public email: string;
  public is_adult = false;
  public charge_account_permitted = false;
  public dob: Date = new Date();
  public golf_enrolled = false;
  public golf_fee = 0;
  public tennis_enrolled: string;
  public tennis_fee = 0;
  public sailing_enrolled = false;
  public sailing_fee = 0;
  public sailing_type: SailingType;
  public adult_count = 1; // this starts as the second adult
  public age: number;
  public sailing_code: string;
  public upsells: UpsellModel[] = [];

  constructor(data?: any) {
    if (data) {
      for (const prop in data) {
        this[prop] = data[prop];
      }
    }
  }

  /**
   * Sets/Updates the model
   */
  setData(data: any, adultCount = 0) {
    for (const prop in data) {
      this[prop] = data[prop];
    }

    this.adult_count = adultCount;

    if (this.getAge() >= 17) {
      this.is_adult = true;
    } else if (this.getAge() > 0 && this.getAge() < 17) {
      this.is_adult = false;
    }

    if (this.is_adult || this.getAge() >= 17) {
      this.sailing_enrolled = false; // only kids can enroll
    }

    if (! this.is_adult && this.getAge() < 17) {
      this.golf_enrolled = false; // only adults can enroll
    }

    if (! this.is_adult ) {
      this.charge_account_permitted = false; // only adults can charge
    }

    this.golf_fee = this.getGolfFee();
    this.tennis_fee = this.getTennisFee();
    this.sailing_fee = this.getSailingFee();
    this.age = this.getAge();
    this.total = this.getTotal();

    if (! this.canEnrollInSailing()) {
      this.sailing_enrolled = false;
    }

    setTimeout(() => {
    }, 1);
  }

  canEnrollInSailing() {
    return ! this.is_adult && (this.getAge() < 17 && this.getAge() >= 5);
  }

  /**
   * Gets the golf Fee
   */
  getGolfFee(): number {
    this.golf_fee = 0;
    if (this.golf_enrolled) {
      return this.golf_fee = SettingsModel.golfFee;
    }

    return this.golf_fee;
  }

  /**
   * Gets the tennis fee
   */
  getTennisFee(): number {
    this.tennis_fee = 0;
    this.tennis_code = '';
    if (this.tennis_enrolled) {
      if (this.is_adult) {
        this.tennis_fee = this.getAdultTennisFee();
        return this.tennis_fee;
      } else {
        const age = this.getAge();

        if (age) {
          if (age >= 4 && age <= 6) {
            this.tennis_code = 'pt';
            this.tennis_fee = SettingsModel.tennisFee56;
            return this.tennis_fee;
          }

          if (age >= 7 && age <= 12) {
            this.tennis_code = 'ct';
            this.tennis_fee = SettingsModel.tennisFee712;
            return this.tennis_fee;
          }

          if (age >= 13 && age <= 16) {
            this.tennis_code = 'yt';
            this.tennis_fee = SettingsModel.tennisFee1316;
            return this.tennis_fee;
          }

          // they must be over 16, process as an adult
          this.tennis_fee = this.getAdultTennisFee();
          return this.tennis_fee;
        }
      }
    }

    return this.tennis_fee;
  }

  /**
   * Gets the adult tennis fee
   */
  private getAdultTennisFee() {
    if (this.tennis_enrolled) {
      if (this.adult_count === 1) {
        this.tennis_code = 'ft';
        return SettingsModel.tennisFeeFirstAdult;
      }

      if (this.adult_count === 2) {
        this.tennis_code = 'at';
        return SettingsModel.tennisFeeSecondAdult;
      }

      this.tennis_code = 'et';
      return SettingsModel.tennisFeeAdditionalAdults;
    }

    this.tennis_code = '';
    return 0;
  }

  /**
   * Gets the sailing fee
   */
  getSailingFee(): number {
    this.sailing_code = '';
    if (this.sailing_enrolled && ! this.is_adult && this.getAge() <= 16) {
      switch (this.sailing_type) {
        case 'childrens_sailing_group_fee_season': {
          this.sailing_code = 'cs';
          return SettingsModel.childrensSailingGroupFeeSeason;
        }
        case 'childrens_sailing_group_fee_4_weeks': {
          this.sailing_code = 'sf';
          return SettingsModel.childrensSailingGroupFee4Weeks;
        }
        case 'childrens_sailing_group_fee_2_weeks': {
          this.sailing_code = 'st';
          return SettingsModel.childrensSailingGroupFee2Weeks;
        }
      }
    }

    return 0;
  }

  /**
   * Gets the total
   */
  getTotal(): number {
    return this.getGolfFee() + this.getTennisFee() + this.getSailingFee();
  }

  getTaxableTotal(): number {
    let taxable = 0;

    if (SettingsModel.golfFeeTaxable) {
      taxable += this.getGolfFee();
    }

    if (SettingsModel.tennisFeeTaxable) {
      taxable += this.getTennisFee();
    }

    if (SettingsModel.childrensSailingGroupFeeTaxable) {
      taxable += this.getSailingFee();
    }

    return taxable;
  }

  /**
   * Gets the age
   */
  getAge() {
    if ( ! this.dob) {
      this.dob = new Date();
    }

    let value = this.dob;

    if ( !value ) {
      return 0;
    }

    if ( !(value instanceof Date)) {
      value = new Date(value);
    }

    const ageDifMs = Date.now() - value.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
