<div class="modal">
  <div class="modal-close">
    <i class="fal fa-times" mat-dialog-close></i>
  </div>
  <div class="modal-inner">
    <div class="row center">
      <div class="col align-center">
        <div *ngIf="!formProcessed">
          <h2 *ngIf="formType === 'login'">Member Login</h2>
          <h2 *ngIf="formType !== 'login'">Forgot Password</h2>
        </div>
        <div class="form">
          <form [formGroup]="form" *ngIf="!formProcessed" novalidate>
            <div class="form-control less-space-top">
              <input placeholder="Email"
                     type="email"
                     [formControl]="form.controls.email"
                     [ngClass]="{ 'is-invalid': form.controls.email.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.email.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="email"></click-api-error>
            </div>
            <div class="form-control" *ngIf="formType === 'login'">
              <div class="has-password-toggle relative">
                <input placeholder="Password"
                       type="password"
                       togglePassword
                       [formControl]="form.controls.password"
                       [ngClass]="{ 'is-invalid': form.controls.password.errors && formSubmitted }">
                <p class="form-error" [@inOutAnimation] *ngIf="form.controls.password.errors && formSubmitted">Field is required</p>
                <click-api-error [apiErrors]="apiErrors" key="password"></click-api-error>
              </div>
            </div>
            <div class="align-right">
              <span class="purple pointer larger">
                <span (click)="setFormType('forgot-password')" *ngIf="formType === 'login'">Reset Password</span>
                <span (click)="setFormType('login')" *ngIf="formType !== 'login'">Actually, let's log in.</span>
              </span>
            </div>
            <div class="button-col align-center">
              <button class="btn btn-md btn-purple" type="submit" (click)="submitLogin()" *ngIf="formType === 'login'" [disabled]="formProcessing">
                <span *ngIf="!formProcessing">Log In <i class="fa fa-chevron-circle-right"></i></span>
                <span *ngIf="formProcessing"><i class="fa fa-spin fa-cog"></i></span>
              </button>
              <button class="btn btn-md btn-purple" type="submit" (click)="submitForgotPassword()" *ngIf="formType === 'forgot-password'" [disabled]="formProcessing">
                <span *ngIf="!formProcessing">Reset <i class="fa fa-chevron-circle-right"></i></span>
                <span *ngIf="formProcessing"><i class="fa fa-spin fa-cog"></i></span>
              </button>
              <div class="less-space-top align-center" *ngIf="apiErrors">
                <p class="form-error align-center">{{ apiErrors.error.detail }}. Have you tried resetting your password?</p>
              </div>
            </div>
          </form>
        </div>
        <div class="form-success" *ngIf="formProcessed && formType === 'forgot-password'">
          <h3>You will receive your new password via e-mail shortly.</h3>
        </div>
      </div>
    </div>
  </div>
</div>
