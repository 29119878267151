import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MembershipFormComponent } from './membership-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SubaccountComponent } from './subaccount/subaccount.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {TooltipModule, TooltipOptions} from 'ng2-tooltip-directive';
import { CustomTooltipOptions } from '../tooltip-options';
import { DropdownsModule } from '@click/dropdowns';
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import {NgxMaskModule} from 'ngx-mask';
import { ClickModule } from '@click/common';
import {RouterModule, Routes} from '@angular/router';
import {DirectivesModule} from '../directives/directives.module';
import {MembershipFormNewComponent} from './membership-form-new.component';
import {StripeModule} from 'stripe-angular';

const routes: Routes = [
    {path: '', component: MembershipFormNewComponent},
    {path: 'renew', component: MembershipFormComponent},
  ];

@NgModule({
  declarations: [MembershipFormComponent, SubaccountComponent, MembershipFormNewComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DropdownsModule,
    SweetAlert2Module,
    ClickModule,
    DirectivesModule,
    TooltipModule.forRoot(CustomTooltipOptions as TooltipOptions),
    AngularStickyThingsModule,
    RouterModule.forChild(routes),
    NgxMaskModule.forRoot(),
    StripeModule
  ],
  exports: [MembershipFormComponent, MembershipFormNewComponent]
})
export class MembershipFormModule { }
