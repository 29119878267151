import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '@click/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Subscription} from "rxjs";
import {inOut} from '../../app.animations';
import {MembershipModel} from '../../models/membership.model';

@Component({
  selector: 'app-edit-main-account',
  templateUrl: './edit-main-account.component.html',
  styleUrls: ['./edit-main-account.component.scss'],
  animations: [inOut]
})
export class EditMainAccountComponent implements OnInit {

  public form: FormGroup;
  public formSubmitted = false;
  public formProcessing = false;
  public formProcessed = false;
  public apiErrors: any;
  private formChangeSubscription: Subscription;

  public member: MembershipModel;
  public editableModel: MembershipModel;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditMainAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.member = data.member;
  }

  ngOnInit(): void {

    this.editableModel = new MembershipModel();
    this.editableModel.setData(this.data.member);

    this.form = this.formBuilder.group({
      first_name: [this.editableModel.first_name, [Validators.required]],
      last_name: [this.editableModel.last_name, [Validators.required]],
      email: [this.editableModel.email, [Validators.email, Validators.required]],
      saltaire_address: [this.editableModel.saltaire_address, [Validators.required]],
      saltaire_phone: [this.editableModel.saltaire_phone, [Validators.required]],
      winter_address: [this.editableModel.winter_address, [Validators.required]],
      winter_address_two: [this.editableModel.winter_address_two, []],
      winter_city: [this.editableModel.winter_city, [Validators.required]],
      winter_state: [this.editableModel.winter_state, [Validators.required]],
      winter_zip: [this.editableModel.winter_zip, [Validators.required]],
      winter_phone: [this.editableModel.winter_phone, [Validators.required]],
      is_renter: [this.editableModel.is_renter, [Validators.required]],
      owner_name: [this.editableModel.owner_name, []],
    });

    this.formChangeSubscription = this.form.valueChanges.subscribe((values) => {
      this.onFormChange(values);
    });
  }

  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
  }

  resetDOB() {
    this.form.controls.dob.setValue(new Date());
  }

  onFormChange(values) {
    this.editableModel.setData(values);
  }

  getPhoneMask() {
    return '(000) 000-0000';
  }


  submit() {
    this.formSubmitted = true;
    if (!this.form.valid || this.formProcessing) {
      return;
    }
    this.dialogRef.close({event: 'UPDATE', data: this.editableModel});
  }

}
