import { Component, OnInit } from '@angular/core';
import {ApiService, AuthenticationService} from '@click/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  public form: FormGroup;
  public formSubmitted = false;
  public formProcessing = false;
  public formProcessed = false;
  public apiErrors: any;
  public formType: string;

  constructor(
    private dialogRef: MatDialogRef<LoginModalComponent>,
    private apiService: ApiService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.formType = 'login';
  }


  setFormType(formType) {

    this.formType = formType;

    if(this.formType === 'login') {
      this.form.controls.password.setValidators([Validators.required]);
    } else {
      this.form.controls.password.clearValidators();
    }

    this.apiErrors = null;
    this.form.controls.password.updateValueAndValidity();

  }




  async submitLogin() {

    this.formSubmitted = true;

    if (!this.form.valid || this.formProcessing) {
      return;
    }

    this.formProcessing = true;

    const model = {
      email: this.form.controls.email.value,
      password: this.form.controls.password.value
    };

    try {
      const user = await this.authenticationService.authenticate( model , 'membership/authenticate');
      if (user.isValid()) {
        this.dialogRef.close([]);
        this.router.navigate(['/dashboard']);
      }
    } catch (error) {
      this.apiErrors = error;
      this.formProcessing = false;
    }
  }

  submitForgotPassword() {
    this.formSubmitted = true;

    if (!this.form.valid || this.formProcessing) {
      return;
    }

    this.formProcessing = true;

    const model = {
      email: this.form.controls.email.value
    };

    this.apiService.post('membership/reset-password', model).then(response => {
      this.formProcessed = true;
    }).catch((error) => {
      this.apiErrors = error;
    }).finally(() => {
      this.formProcessing = false;
    });

  }

}
