import {Component, OnInit, ViewChild} from '@angular/core';
import {UpgradeComponent} from '../modals/upgrade/upgrade.component';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {EditSubaccountComponent} from '../modals/edit-subaccount/edit-subaccount.component';
import {ApiService, AuthenticatedUserModel} from '@click/common';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {MemberModel} from '../models/member.model';
import {Chargable} from '../shared/chargable';
import {EditMainAccountComponent} from '../modals/edit-main-account/edit-main-account.component';
import {SubaccountModel} from '../models/subaccount.model';
import {ChangePasswordComponent} from '../modals/change-password/change-password.component';
import {LoginModalComponent} from '../modals/login-modal/login-modal.component';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends Chargable implements OnInit {

  // probably should actually be a model rather than any type?
  public member: MemberModel;
  public currentYear: number;
  public authedUser: any;


  @ViewChild('successSwal') private successSwal: SwalComponent;
  @ViewChild('failSwal') private failSwal: SwalComponent;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {


    this.authedUser = new AuthenticatedUserModel();

    if (this.authedUser.isValid()) {
      this.apiService.get('membership/member/' + this.authedUser.id).then(response => {
        this.member = new MemberModel(response);
        console.log('member', this.member);
      }).catch(error => {
        if (error.status === 401 || error.status === 403 || error.status === 404) {
          this.authedUser.destroyUser();
          this.router.navigate([]);
        }
      });
    } else {
      this.router.navigate(['/']);
    }

    const date = new Date();
    this.currentYear = date.getFullYear();
  }

  editMainAccount() {

    const dialogRef = this.dialog.open(EditMainAccountComponent,
      {
        panelClass: ['dialog-no-padding', 'dialog-height-auto'],
        data: {
          member: this.member
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event === 'UPDATE') {
        console.log('result', result.data);
        this.member = result.data;
        this.updateAccount();
      }

    });

  }
  changePassword() {
    const dialogRef = this.dialog.open(ChangePasswordComponent,
      {
        panelClass: ['dialog-no-padding', 'dialog-height-auto'],
        data: {
          member: this.member
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event === 'UPDATE') {
        this.apiService.post('membership/change-password', {
          member_id: this.member._id.$oid,
          password: result.data.password
        }).catch((error) => {
          this.failSwal.fire();
        });
      }
    });
  }
  editSubaccount(index) {

    const dialogRef = this.dialog.open(EditSubaccountComponent,
      {
        panelClass: 'dialog-no-padding',
        data: {
          subaccount: this.member.subaccounts[index]
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event === 'UPDATE') {
        this.member.subaccounts[index] = result.data;
        this.updateAccount();
      } else {
        if (this.member.subaccounts[index].removeIfEmpty) {
          this.member.subaccounts.splice(index, 1);
        }
      }
    });
  }

  addSubaccount() {
    const acct = new SubaccountModel();
    acct.removeIfEmpty = true;
    this.member.subaccounts.push(acct);
    this.editSubaccount(this.member.subaccounts.length - 1);
  }

  upgradeSubaccounts() {

    const dialogRef = this.dialog.open(UpgradeComponent,
      {
        panelClass: ['dialog-no-padding', 'dialog-height-auto'],
        data: {
          member: this.member
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result.event && result.event === 'UPDATE') {
        this.member = result.data;
        this.refreshData();
      }
    });

  }

  refreshData() {
    this.apiService.get('membership/member/' + this.authedUser.id).then(response => {
      this.member = new MemberModel(response);
      console.log('member', this.member);
    }).catch(error => {
      if (error.status === 401 || error.status === 403 || error.status === 404) {
        this.authedUser.destroyUser();
        this.router.navigate([]);
      }
    });
  }

  removeSubaccount(index) {
    this.member.subaccounts.splice(index, 1);
    this.updateAccount();
  }

  updateAccount() {
    this.apiService.put('membership/member/' + this.authedUser.id, this.member).then(response => {
      this.member = new MemberModel(response);
      console.log('updated member', this.member);
      this.successSwal.fire();
      // not sure we need this... this.successSwal.fire();
    }).catch(error => {
      console.log('err', error);
      this.failSwal.fire();
    });
  }
}
