<h1 class="align-center" *ngIf="!member"><i class="fa fa-spinner fa-spin"></i></h1>

<section class="dashboard" *ngIf="member">
  <div class="container">
    <div class="content border-top" *ngIf="member.membership_year < currentYear">
      <div class="row center has-caret-down space-bottom membership-warning">
        <div class="col-44 align-center">
          <i class="fa fa-caret-down caret less-space"></i>
          <h4>You have not renewed your membership for {{currentYear}}!</h4>
          <div class="align-center button-row">
            <a routerLink="/renew/renew" class="btn btn-sm btn-purple">Renew <i class="fa fa-chevron-circle-right"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="row wrap-sm">
      <div class="col">
        <div class="panel-wrap">
          <div class="panel">
            <div class="row center">
              <div class="col align-center">
                <h3>Main Account</h3>
              </div>
            </div>
            <div class="panel-section">
              <div class="row">
                <div class="col">
                  <h4>{{ member.first_name }} {{ member.last_name }}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>Email:</h5>
                </div>
                <div class="col">
                  <h4>{{ member.email }}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>Membership Level:</h5>
                </div>
                <div class="col">
                  <h4>{{ member.membership_level }}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>Membership Year:</h5>
                </div>
                <div class="col">
                  <h4>{{ member.membership_year }}</h4>
                </div>
              </div>
              <div class="row" *ngIf="member.discounts.length">
                <div class="col">
                  <h5>Account Credit:</h5>
                </div>
                <div class="col">
                  <h4>{{member.getAccountCredit() | currency}}</h4>
                </div>
              </div>
            </div>
            <div class="panel-section">
              <div class="row">
                <div class="col">
                  <h4>Saltaire Address</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>Address:</h5>
                </div>
                <div class="col">
                  <h4>{{ member.saltaire_address }}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>Phone</h5>
                </div>
                <div class="col">
                  <h4>{{ member.saltaire_phone }}</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>Renting:</h5>
                </div>
                <div class="col">
                  <h4>{{ member.is_renter | yesNo }}</h4>
                </div>
              </div>
              <div class="row" *ngIf="member.is_renter">
                <div class="col">
                  <h5>Owner's Name</h5>
                </div>
                <div class="col">
                  <h4>{{ member.owner_name }}</h4>
                </div>
              </div>
            </div>
            <div class="panel-section">
              <div class="row">
                <div class="col">
                  <h4>Winter Address</h4>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h5>Address:</h5>
                </div>
                <div class="col">
                  <h4>{{ member.winter_address }}<span *ngIf="member.winter_address_two"> {{ member.winter_address_two }}</span>,
                    {{ member.winter_city }}, {{ member.winter_state }} {{ member.winter_zip }}</h4>
                </div>
              </div>
              <div class="panel-section">
                <div class="row">
                  <div class="col">
                    <h4>Enrolled Activities:</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h5>Golf:</h5>
                  </div>
                  <div class="col">
                    <h4>{{ member.golf_enrolled | yesNo }}</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h5>Tennis:</h5>
                  </div>
                  <div class="col">
                    <h4>{{ member.tennis_enrolled | yesNo }}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="row less-space-top center button-row">
              <div class="col-free button-col">
                <button class="btn btn-xs btn-purple" (click)="editMainAccount()"><i class="fa fa-edit"></i> Edit</button>
              </div>
              <div class="col-free button-col" *ngIf="member.membership_year >= currentYear">
                <button class="btn btn-xs btn-purple" (click)="upgradeSubaccounts()">Upgrade <i class="fa fa-plus-circle"></i></button>
              </div>
            </div>
            <div class="row less-space-top center button-row">
              <div class="col-free button-col">
                <p (click)="changePassword()"><i class="fa fa-edit"></i> Change Password</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="align-center extra-space-top space-sm-top no-subaccounts" *ngIf="!member.subaccounts.length">
          <h3>You currently have no subaccounts.</h3>
        </div>
        <div class="panel-wrap" *ngFor="let sub of member.subaccounts; let i = index;">
          <div class="panel">
            <div class="row center">
              <div class="col align-center">
                <h3>Subaccount</h3>
              </div>
            </div>
            <div class="extra-information">
              <div class="panel-section">
                <div class="row">
                  <div class="col">
                    <h4>{{ sub.first_name }} {{ sub.last_name }} <span class="light" *ngIf="!sub.is_adult">(Age {{ sub.getAge() }})</span><span class="light" *ngIf="sub.is_adult">(Adult)</span></h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h5><span *ngIf="!sub.is_adult">Parent's </span>Email:</h5>
                  </div>
                  <div class="col">
                    <h4>{{ sub.email }}</h4>
                  </div>
                </div>
                <div class="row" *ngIf="sub.is_adult">
                  <div class="col">
                    <h5>Restaurant Account:</h5>
                  </div>
                  <div class="col">
                    <h4>{{ sub.charge_account_permitted | yesNo }}</h4>
                  </div>
                </div>
              </div>
              <div class="panel-section">
                <div class="row">
                  <div class="col">
                    <h4>Enrolled Activities:</h4>
                  </div>
                </div>
                <div class="row" *ngIf="!sub.is_adult">
                  <div class="col">
                    <h5>Sailing:</h5>
                  </div>
                  <div class="col">
                    <h4>
                      <span *ngIf="sub.sailing_enrolled">
                        <span *ngIf="sub.sailing_type == 'childrens_sailing_group_fee_season'">Season</span>
                        <span *ngIf="sub.sailing_type == 'childrens_sailing_group_fee_4_weeks'">4 Weeks</span>
                        <span *ngIf="sub.sailing_type == 'childrens_sailing_group_fee_2_weeks'">2 Weeks</span>
                      </span>
                      <span *ngIf="!sub.sailing_enrolled">No</span>
                    </h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h5>Tennis:</h5>
                  </div>
                  <div class="col">
                    <h4>{{ sub.tennis_enrolled | yesNo }}</h4>
                  </div>
                </div>
                <div class="row" *ngIf="sub.is_adult">
                  <div class="col">
                    <h5>Golf:</h5>
                  </div>
                  <div class="col">
                    <h4>{{ sub.golf_enrolled | yesNo }}</h4>
                  </div>
                </div>
              </div>
              <div class="row center button-row">
                <div class="col-free button-col">
                  <button class="btn btn-xs btn-purple" (click)="editSubaccount(i)"><i class="fa fa-edit"></i> Edit</button>
                </div>
                <div class="col-free button-col">
                  <swal
                    #deleteSwal
                    title="Remove {{ sub.first_name }}?"
                    text="This cannot be undone."
                    type="question"
                    [showCancelButton]="true"
                    [focusCancel]="true"
                    (confirm)="removeSubaccount(i)">
                  </swal>
                  <button class="btn btn-xs btn-purple" [swal]="deleteSwal">Remove <i class="fa fa-minus-circle"></i></button>
                </div>
                <div class="col-free button-col" *ngIf="member.membership_year >= currentYear">
                  <button class="btn btn-xs btn-purple" (click)="upgradeSubaccounts()">Upgrade <i class="fa fa-plus-circle"></i></button>
                </div>
                <swal
                  #successSwal
                  title="Success!"
                  text="Your account has been updated"
                  icon="success"
                  [showCancelButton]="false"
                  [focusConfirm]="true"
                  [confirmButtonText]="'OK'"
                >
                </swal>
                <swal
                  #failSwal
                  title="Oops!"
                  text="There was an issue updating your account. Please try again."
                  icon="warning"
                  [showCancelButton]="false"
                  [focusConfirm]="true"
                  [confirmButtonText]="'OK'"
                >
                </swal>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-wrap">
          <div class="row center button-row">
            <div class="col-free button-col">
              <button class="btn btn-xs btn-purple" (click)="addSubaccount()"><i class="fa fa-edit"></i> Add</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div *ngIf="cardInvalidError" style="color:red">
    {{ cardInvalidError.message }}
  </div>

  <stripe-card #stripeCard
               (catch) = "onStripeError($event)"
               [(complete)] = "cardDetailsFilledOut"
               [(invalid)] = "cardInvalidError"
               (cardMounted) = "cardCaptureReady"
               (paymentMethodChange) = "setPaymentMethod($event)"
               (tokenChange) = "setStripeToken($event)"
               (sourceChange) = "setStripeSource($event)"
  ></stripe-card>
  <button type="button" (click)="stripeCard.createPaymentMethod(extraData)">createPaymentMethod</button>
  <button type="button" (click)="stripeCard.createSource(extraData)">createSource</button>
  <button type="button" (click)="stripeCard.createToken({amount: 1000})">createToken</button>-->
</section>
