import { Injectable } from '@angular/core';
import {ApiService} from '@click/common';
import {UpsellModel} from '../models/upsell.model';

@Injectable({
  providedIn: 'root'
})
export class UpsellsService {

  private static upsells: UpsellModel[] = null;
  constructor(private apiService: ApiService) { }

  getUpsells(): Promise<UpsellModel[]> {
    return new Promise<UpsellModel[]>((resolve, reject) => {
      if (UpsellsService.upsells !== null) {
        resolve(UpsellsService.upsells);
        return;
      }

      this.apiService.get('membership/up-sell').then(results => {
        UpsellsService.upsells = results._embedded.up_sell.filter((item) => {
          return item.active;
        });
        resolve(UpsellsService.upsells);
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
