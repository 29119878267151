import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ClickModule} from '@click/common';
import {environment} from '../environments/environment';
import {MembershipFormModule} from './membership-form/membership-form.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {SharedModule} from './shared/shared.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DashboardModule} from './dashboard/dashboard.module';
import {NgxMaskModule} from 'ngx-mask';
import {ModalsModule} from './modals/modals.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {StripeModule} from 'stripe-angular';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    MembershipFormModule,
    DashboardModule,
    ModalsModule,
    ClickModule.forRoot({apiUrl: environment.apiUrl, logging: environment.logging, loginRoute: '/'}),
    BrowserAnimationsModule,
    SweetAlert2Module.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    StripeModule.forRoot(environment.stripe.publishable_key)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
