<header class="content hide-for-print">
  <div class="grey-top"></div>
  <div class="container">
    <div class="row center">
      <div class="col align-center">
        <img class="logo" src="/assets/images/common/logo.png" alt="">
        <h2>Saltaire Yacht Club, <span class="block-xs">Village of Saltaire</span></h2>
        <h1>{{currentYear}} Membership Application/Renewal</h1>
        <!--<h3>Please submit by May 1, {{currentYear}}</h3>-->
      </div>
    </div>
    <div class="row button-row center wrap-xs" *ngIf="!user.isValid()">
      <div class="col-free col-xs-48">
        <a class="button-text-only purple" (click)="openLoginDialog()">
          Member Login
          <i class="fa fa-chevron-circle-right"></i>
        </a>
      </div>
      <div class="col-free offset-3 col-xs-48 xs-offset-0">
        <a (click)="slideToForm()" class="button-text-only purple">
          Become a Member
          <i class="fa fa-chevron-circle-right"></i>
        </a>
      </div>
    </div>
    <div class="row button-row center wrap-xs" *ngIf="user.isValid()">
      <div class="col-free col-xs-48">
        <a class="button-text-only purple" (click)="logout()">
          Logout
          <i class="fa fa-chevron-circle-right"></i>
        </a>
      </div>
    </div>
  </div>
</header>
