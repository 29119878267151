<div class="modal">
  <div class="modal-close">
    <i class="fal fa-times" mat-dialog-close></i>
  </div>
  <div class="modal-inner">
    <div class="row center">
      <div class="col align-center">
        <h2>Edit - {{ member.first_name }} {{ member.last_name }}</h2>
        <div class="form less-space-top">
          <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!formProcessed" novalidate>
            <div class="form-control less-space-top">
              <input placeholder="First Name"
                     type="text"
                     required
                     [formControl]="form.controls.first_name"
                     [ngClass]="{ 'is-invalid': form.controls.first_name.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.first_name.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="first_name"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="Last Name"
                     type="text"
                     required
                     [formControl]="form.controls.last_name"
                     [ngClass]="{ 'is-invalid': form.controls.last_name.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.last_name.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="last_name"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="Email"
                     type="email"
                     required
                     pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                     [formControl]="form.controls.email"
                     [ngClass]="{ 'is-invalid': form.controls.email.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.email.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="email"></click-api-error>
            </div>
            <h4 class="align-center">Saltaire Address</h4>
            <div class="form-control">
              <input placeholder="Saltaire Address"
                     type="text"
                     required
                     [formControl]="form.controls.saltaire_address"
                     [ngClass]="{ 'is-invalid': form.controls.saltaire_address.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.saltaire_address.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="saltaire_address"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="Saltaire Phone" [mask]="getPhoneMask()" type="text" [formControl]="form.controls.saltaire_phone"
                     [ngClass]="{ 'is-invalid': form.controls.saltaire_phone.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.saltaire_phone.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="saltaire_phone"></click-api-error>
            </div>

            <h4 class="align-center">Winter Address</h4>
            <div class="form-control">
              <input placeholder="Address" type="text" [formControl]="form.controls.winter_address"
                     [ngClass]="{ 'is-invalid': form.controls.winter_address.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_address.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="winter_address"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="Address 2" type="text" [formControl]="form.controls.winter_address_two"
                     [ngClass]="{ 'is-invalid': form.controls.winter_address_two.errors && formSubmitted }">
              <click-api-error [apiErrors]="apiErrors" key="winter_address_two"></click-api-error>
            </div>
            <div class="form-control">
              <input placeholder="City" type="text" [formControl]="form.controls.winter_city"
                     [ngClass]="{ 'is-invalid': form.controls.winter_city.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_city.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="winter_city"></click-api-error>
            </div>
            <div class="row space-between wrap-xs">
              <div class="col-24 col-xs-48">
                <div class="form-control">
                  <input placeholder="State" type="text" [formControl]="form.controls.winter_state"
                         [ngClass]="{ 'is-invalid': form.controls.winter_state.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_state.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="winter_state"></click-api-error>
                </div>
              </div>
              <div class="col-24 col-xs-48">
                <div class="form-control">
                  <input placeholder="Zip Code" [mask]="'99999'" type="text" [formControl]="form.controls.winter_zip"
                         [ngClass]="{ 'is-invalid': form.controls.winter_zip.errors && formSubmitted }">
                  <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_zip.errors && formSubmitted">Field is required</p>
                  <click-api-error [apiErrors]="apiErrors" key="winter_zip"></click-api-error>
                </div>
              </div>
            </div>
            <div class="form-control">
              <input placeholder="Winter Phone" type="text" [mask]="getPhoneMask()" [formControl]="form.controls.winter_phone"
                     [ngClass]="{ 'is-invalid': form.controls.winter_phone.errors && formSubmitted }">
              <p class="form-error" [@inOutAnimation] *ngIf="form.controls.winter_phone.errors && formSubmitted">Field is required</p>
              <click-api-error [apiErrors]="apiErrors" key="winter_phone"></click-api-error>
            </div>
            <div class="form-control align-center">
              <div class="less-space-bottom">
                <h4>Are you renting the property at {{editableModel.saltaire_address}}?</h4>
              </div>
              <div class="row v-center center"  [ngClass]="{ 'radio-invalid': form.controls.is_renter.errors && formSubmitted }">
                <div class="col-free">
                  <label class="checkbox">
                    <div class="row v-center center">
                      <div class="col-free">
                        <input type="radio"
                               name="is_renter"
                               [value]="true"
                               class="form-check-input"
                               [formControl]="form.controls.is_renter">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">Yes</span>
                      </div>
                    </div>
                  </label>
                </div>
                <div class="col-free offset-2">
                  <label class="checkbox">
                    <div class="row v-center">
                      <div class="col-free">
                        <input type="radio"
                               name="is_renter"
                               [value]="false"
                               class="form-check-input"
                               [formControl]="form.controls.is_renter">
                        <span class="custom-checkbox"></span>
                      </div>
                      <div class="col-free">
                        <span class="label">No</span>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="row center" *ngIf="form.controls.is_renter.errors && formSubmitted">
                <div class="col-free align-center">
                  <p class="form-error" [@inOutAnimation]>Field is required</p>
                </div>
              </div>
              <click-api-error [apiErrors]="apiErrors" key="is_renter"></click-api-error>
            </div>
            <div class="form-control less-space-top" *ngIf="editableModel.is_renter">
              <input placeholder="Property Owner's Name" type="text"
                     [ngClass]="{ 'is-invalid': form.controls.owner_name.errors && formSubmitted }"
                     [formControl]="form.controls.owner_name">
              <click-api-error [apiErrors]="apiErrors" key="owner_name"></click-api-error>
            </div>
            <div class="button-col align-center">
              <button class="btn btn-md btn-purple" type="submit">
                <span *ngIf="!formProcessing">Save<i class="fa fa-chevron-circle-right margin-left"></i></span>
                <span *ngIf="formProcessing"><i class="fa fa-spin fa-cog"></i></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
